.carousel-demo .product-item .product-item-content {
  border: 1px solid var(--surface-d);
  border-radius: 3px;
  text-align: center;
  padding: 0.8rem 0;
}

.carouselCard {
  width: 100%;
  height: 91vh;
  margin-top: 4em;
}

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.home-container>h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.home-container>p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.home-btns {
  margin-top: 20px;
}

.home-btns .btn {
  margin: 6px;
}


.first-container {
  background-size: cover;
  width: 100%;
  height: 984px;
  color: #666666;
  -webkit-font-smoothing: antialiased;
  margin-top: -96px;
}


.main-carousel-card>div>div>div>div {
  width: 100%;
}

.home-content {
  position: absolute;
  width: 48%;
  height: 40%;
  top: 34%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(42, 40, 40);
  /* background-color: rgba(144, 131, 131, 0.1); */
  left: 48%;
  padding: 3%;
}

.home-para-h3 {
  font-size: 30px;
  display: flex;
  flex-wrap: wrap;
}

#home_Company_obj {
  font-weight: 600;
  margin-top: 2%;
  margin-bottom: 2%;
}

button.p-button.p-component.home-btn-starts {
  padding: 1.2rem 2.2rem;
  background-color: var(--ps-orange);
  transition: var(--ps-orange) 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  font-size: 25px;
  color: #ffffff;
  line-height: 1;
  border-radius: 35px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  border: none;
}

button.p-button.p-component.home-btn-starts:hover {
  background-color: var(--ps-orange-hover);
  background: var(--ps-orange-hover);
  color: #ffffff;
}


.carousel-demo .product-item .product-image {
  width: 100%;
  height: 100%;
}

.carouselCard>.p-carousel-container {
  height: 50em;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #007cdf !important;
  padding: 0rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #242527 !important;
  padding: 0rem;
}

.carousel-demo .product-item .product-item-content {
  border-color: transparent;
}

/* //--------------------------- */
#btn_Begin {
  padding: 1rem 2.2rem;
  background-color: var(--ps-orange);
  transition: var(--ps-orange) 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border: 1px solid var(--ps-orange);
  font-size: 25px;
  color: white;
  line-height: 1;
  border-radius: 35px;
  outline: none;
  transition: all 0.3s;
  margin: 0;
  border: none;
  cursor: pointer;

  font-size: 25px;
  font-weight: 500;
}

#btn_Begin:hover {
  background-color: var(--ps-orange-hover);
  background: var(--ps-orange-hover);
}


#btn_Begin span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

#btn_Begin span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

#btn_Begin:hover span {
  padding-right: 25px;
}

#btn_Begin:hover span:after {
  opacity: 1;
  right: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #dbe2ef;
  width: 10px !important;
  height: 10px !important;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50% !important;
  padding: 0rem;
}

.digital-main-title {
  font-size: 30px;
  font-weight: 600;
}

.prices-div-home {
  background-color: #f9f7f7;
  width: 100%;
  margin-top: 48.8%;
}

.upper-text-carousel-left {
  position: absolute;
  width: 41%;
  height: 28%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  object-fit: contain;
  top: 20%;
  margin-left: 48%;
  font-size: 20px;
  /* font-weight: bold; */
  color: #3d3d3d;
  /* border: 1px solid rgba(144, 131, 131, 0.1);
  background-color: rgba(144, 131, 131, 0.1); */
}

.pageCompanyDetail {
  color: black;

}

.price-tag-color {
  color: rgb(255, 0, 0);
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 2%;
}


.upper-text-carousel-right {
  position: absolute;
  width: 39%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1%;
  object-fit: contain;
  top: 25%;
  margin-left: 3%;
  font-size: 20px;
  /* font-weight: bold; */
  color: #3d3d3d;
  /* border: 1px solid rgba(144, 131, 131, 0.1);
  background-color: rgba(144, 131, 131, 0.1); */

}


.about-div-home {
  border: 1px solid white;
  background: #b2cbf9;
  width: 100%;
  height: 98vh;
  position: absolute;
}

.faq-div-home {
  background-color: #bdd3fc;
  width: 100%;
  height: 82vh;
}

.text-inside-Btn {
  position: absolute;
  color: black;
  font-size: 25px;
  width: 7%;
  text-align: center;
  top: 23%;
  margin-left: 46%;
  border: none;
  border-radius: 6%;
  height: 4%;
  background: #dbe2ef;
}

#paymentSucessTick {
  font-size: 58px;
  color: green;
}

.paymentSuccessLbl {
  font-size: 20px;
}

.paymentSuccessConfetti {
  padding-left: 50%;
  padding-right: 50%;
  top: 35%;
  position: absolute;
}

/* NewCarousel css */
.new-slider {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .new-wrapper {
    height: 100vh;
    transition: .5s all linear;
    will-change: transform;
  }

  .arrows {
    position: absolute;
    top: 50%;
    background: none;
    height: 60px;
    border: 0;
    cursor: pointer;
    transition: ease .3s all;
    outline: none;

    &.prev {
      left: 0;

      &:hover {
        opacity: .7;
        left: -10px;
      }
    }

    &.next {
      right: 0;

      &:hover {
        right: -10px;
        opacity: .7;
      }
    }
  }

  .new-dots-container {
    height: auto;
    margin: 0;
    padding: 0;
    position: absolute;
    width: auto;
    text-align: center;
    left: 50%;
    bottom: 9px;
    transform: translateX(-50%);
    z-index: 10;
    list-style-type: none;

    li {
      display: inline-block;
      padding: 5px;

      &.active {
        button {
          color: #00D8FF;
        }
      }

      button {
        color: #fff;
        background-color: transparent;
        border: none;

        &:hover {
          text-decoration: none;
          opacity: .7;
          cursor: pointer;
        }
      }
    }
  }

  .new-toggle-play {
    background: transparent;
    border: none;
    height: auto;
    position: absolute;
    width: auto;
    right: 5%;
    bottom: 9px;
    color: #3d3d3d;
    z-index: 1000000;

    &:hover {
      text-decoration: none;
      opacity: .7;
      cursor: pointer;
    }
  }

  .each-slide {
    width: 100vw;
    height: 100vh;
    float: left;
    line-height: 100vh;
    font-size: 40vh;
    text-align: center;
    background-size: cover;
    background-position: center center;
    background-color: transparent;
  }
}

@media screen and (max-width: 1920px) {
  .carousel-demo {
    margin-top: 3.7%;
  }

  .faq-div-home {
    background-color: #bdd3fc;
    width: 100%;
    height: 82vh;
  }

}

@media screen and (max-width: 1870px) {
  .upper-text-carousel-left {
    width: 43%;
  }

  .upper-text-carousel-right {
    width: 39%;
  }
}

@media screen and (max-width: 1770px) {
  .upper-text-carousel-left {
    width: 42%;
  }

  .upper-text-carousel-right {
    width: 38%;
  }

  .prices-div-home {
    margin-top: 48%;
  }
}

@media screen and (max-width: 1675px) {
  .upper-text-carousel-left {
    width: 40%;
  }

  .upper-text-carousel-right {
    width: 38%;
  }

  #btn_Begin {
    padding: 1.5rem 1.5rem;
  }
}

@media screen and (max-width: 1536px) {
  .upper-text-carousel-left {
    width: 40%;
    font-size: 25px;
  }

  .price-tag-color {
    font-size: 30px;
  }

  .upper-text-carousel-right {
    width: 39%;
  }

  .prices-div-home {
    margin-top: 53%;
  }
}


@media screen and (max-width: 1500px) {
  .upper-text-carousel-left {
    width: 37%;
    font-size: 25px;
    margin-top: 4%;
  }
}

@media screen and (max-width: 1441px) {
  .upper-text-carousel-left {
    width: 38%;
  }

  .upper-text-carousel-right {
    width: 41%;
  }

  .prices-div-home {
    margin-top: 56%;
  }
}

@media screen and (max-width: 1386px) {
  .upper-text-carousel-right {
    width: 44%;
    font-size: 23px;
  }

  .prices-div-home {
    margin-top: 58%;
  }
}

@media screen and (max-width: 1350px) {

  #btn_Begin {
    top: 2%;
  }

  .prices-div-home {
    margin-top: 59%;
  }
}

@media screen and (max-width: 1300px) {
  .first-container {
    height: 825px !important;
  }

  .upper-text-carousel-left {
    width: 40%;
    margin-top: 3%;
    height: 32%;
    font-size: 23px;
  }

  .price-tag-color {
    font-size: 29px;
  }

  #btn_Begin {
    top: 1%;
    font-size: 20px;
    padding: 16px;
  }

  .upper-text-carousel-right {
    width: 44%;
    font-size: 23px;
    margin-left: 1%;
  }

  .prices-div-home {
    margin-top: 64%;
  }
}

@media screen and (max-width: 1280px) {
  .upper-text-carousel-left {
    width: 42%;
    margin-left: 46%;
    margin-top: 7%;
  }

  .upper-text-carousel-right {
    width: 42%;
    margin-top: 6%;
  }

  #btn_Begin {
    top: 1%;
    font-size: 20px;
    padding: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .upper-text-carousel-left {
    width: 42%;
    margin-left: 46%;
    margin-top: 7%;
  }

  .upper-text-carousel-right {
    width: 42%;
    margin-top: 6%;
  }

  .price-tag-color {
    font-size: 31px;
    font-weight: 600;
  }

  #btn_Begin {
    top: 1%;
    font-size: 20px;
    padding: 18px;
  }

  .prices-div-home {
    margin-top: 68%;
  }
}

@media screen and (max-width: 1100px) {
  .prices-div-home {
    margin-top: 73%;
  }
}

@media screen and (max-width: 1024px) {
  .carousel-demo .product-item .product-item-content {
    padding: 7.8rem 0;
  }

  .upper-text-carousel-left {
    width: 45%;
    font-size: 23px;
    padding: 4rem 0rem;
    margin-top: 10%;
    margin-left: 44%;
  }

  .carouselDivText {
    font-size: 18px;
  }

  .upper-text-carousel-right {
    width: 45%;
    font-size: 23px;
    padding: 0rem 2rem;
    margin-top: 6%;
  }

  .price-tag-color {
    font-size: 30px;
    font-weight: 600;
  }

  #btn_Begin {
    top: 1%;
    font-size: 20px;
    padding: 23px;
  }

  .prices-div-home {
    margin-top: 79%;
  }
}

@media screen and (max-width: 958px) {

  .first-container {
    width: 100%;
    margin-bottom: -6% !important;
  }

  .upper-text-carousel-left {
    width: 49%;
  }

  #btn_Begin {
    top: 4%;
  }

  .price-tag-color {
    font-size: 30px;
    font-weight: 600;
  }

  .prices-div-home {
    margin-top: 84%;
  }
}


@media screen and (max-width: 912px) {

  .first-container {
    width: 100%;
    margin-bottom: 0% !important;
  }

  .about-div-home {
    height: 91vh;
  }

}

@media screen and (max-width: 900px) {
  .carousel-demo .product-item .product-item-content {
    padding: 8.8rem 0;
    background-color: rgb(94 88 88 / 1%);
  }

  .upper-text-carousel-left {
    width: 48%;
    margin-top: 18%;
  }

  .price-tag-color {
    font-size: 26px;
    font-weight: 600;
  }

  .prices-div-home {
    margin-top: 89%;
  }

}

@media screen and (max-width: 853px) {
  .upper-text-carousel-left {
    width: 48%;
    font-size: 21px;
  }

  .upper-text-carousel-right {
    width: 55%;
    font-size: 19px;
    padding: 2rem 0rem;
    margin-top: 6%;
  }

  .prices-div-home {
    margin-top: 95%;
  }
}

@media screen and (max-width: 834px) {

  .carousel-demo .product-item .product-image {
    width: 128%;
    height: 100%;
  }


  .carousel-demo .product-item .product-item-content {
    padding: 7.8rem 0;
  }

  .first-container {
    width: 100%;
    margin-bottom: 0% !important;
  }

  .upper-text-carousel-left {
    width: 48%;
    font-size: 19px;
    height: 27%;
  }


  .price-tag-color {
    font-size: 26px;
    font-weight: 600;
  }
}


@media screen and (max-width: 800px) {
  .first-container {
    width: 100%;
    margin-bottom: -5% !important;
  }

  .upper-text-carousel-left {
    width: 48%;
    font-size: 19px;
    margin-top: 17%;
    margin-left: 43%;
  }

  .upper-text-carousel-right {
    width: 52%;
    font-size: 19px;
  }

  .prices-div-home {
    margin-top: 98%;
  }
}


@media screen and (max-width: 768px) {
  #btn_Begin {
    top: 1%;
    font-size: 15px;
    padding: 20px;
  }

  .prices-div-home {
    margin-top: 105%;
  }
}



@media screen and (max-width: 754px) {

  .carousel-demo .product-item .product-item-content {
    padding: 8.8rem 0;
  }

  .upper-text-carousel-left {
    width: 54%;
    margin-left: 36%;
    margin-top: 19%;
  }

  #btn_Begin {
    top: 1%;
    font-size: 15px;
    padding: 20px;
  }
}


@media screen and (max-width: 700px) {

  .carousel-demo .product-item .product-item-content {
    padding: 8.8rem 0;
  }

  .first-container {
    width: 100% !important;
    margin-bottom: -5% !important;
  }

  .upper-text-carousel-left {
    margin-top: 21%;
    width: 51%;
  }

  .prices-div-home {
    margin-top: 115%;
  }

  .about-div-home {
    height: 91vh;
  }

}




@media screen and (max-width: 670px) {

  .carousel-demo .product-item .product-image {
    width: 137%;
    height: 100%;
  }

  .carousel-demo .product-item .product-item-content {
    padding: 7.8rem 0;
  }

  .first-container {
    width: 100% !important;
    margin-bottom: -10% !important;
  }

  .prices-div-home {
    margin-top: 120%;
  }

  .carouselDivText {
    display: none;
  }
}

@media screen and (max-width: 610px) {

  .carousel-demo .product-item .product-image {
    width: 137%;
    height: 65vh;
  }


  .first-container {
    width: 100% !important;
    margin-bottom: -22% !important;
  }

  .upper-text-carousel-left {
    font-size: 17px;
    margin-top: 35%;
    width: 53%;
    margin-left: 36%;
  }

  .upper-text-carousel-right {
    font-size: 17px;
    margin-top: 18%;
    width: 65%;
    padding: 18px;
  }

  .prices-div-home {
    margin-top: 132%;
  }

  .price-tag-color {
    font-size: 23px;
    font-weight: 600;
  }
}



@media screen and (max-width: 590px) {
  .carousel-demo .product-item .product-image {
    width: 137%;
    height: 86vh;
  }

  .upper-text-carousel-left {
    font-size: 18px;
    margin-top: 32%;
    width: 53%;
    margin-left: 37%;
  }

  .price-tag-color {
    font-size: 22px;
    font-weight: 600;
  }


  .upper-text-carousel-right {
    width: 65%;
    font-size: 18px;
  }
}

@media screen and (max-width: 550px) {
  .prices-div-home {
    margin-top: 152%;
  }

  .about-div-home {
    height: 91vh;
  }

}

@media screen and (max-width: 540px) {
  .carousel-demo .product-item .product-image {
    width: 137%;
    height: 70vh;
  }

  .upper-text-carousel-left {
    font-size: 18px;
    margin-top: 35%;
    width: 56%;
    margin-left: 36%;
  }
}

@media screen and (max-width: 500px) {
  .prices-div-home {
    margin-top: 119%;
  }
}


@media screen and (max-width: 430px) {

  .about-div-home {
    height: 81vh;
  }

  .carousel-demo .product-item .product-image {
    width: 137%;
    height: 78vh;
  }

  .upper-text-carousel-left {
    font-size: 16px;
    margin-top: 63%;
    width: 62%;
    margin-left: 29%;
  }

  .upper-text-carousel-right {
    width: 65%;
    font-size: 15px;
  }

  .price-tag-color {
    font-size: 30px;
    font-weight: 600;
  }

  #btn_Begin span {
    font-size: 15px;
  }

  .prices-div-home {
    margin-top: 139%;
  }

  .faq-div-home {
    background-color: #bdd3fc;
    height: 95vh;
  }

}

@media screen and (max-width: 414px) {
  .prices-div-home {
    margin-top: 165%;
  }

  .upper-text-carousel-right {
    width: 73%;
    font-size: 1px;
  }

  .upper-text-carousel-left {
    font-size: 1px;
    margin-top: 40%;
    width: 61%;
    margin-left: 29%;
  }


}


@media screen and (max-width: 390px) {
  .upper-text-carousel-left {
    font-size: 1px;
    margin-top: 40%;
    width: 61%;
    margin-left: 29%;
  }

  .upper-text-carousel-right {
    width: 73%;
    font-size: 1px;
  }

  .price-tag-color {
    font-size: 30px;
    font-weight: 600;
  }

  .carousel-demo .product-item .product-image {
    width: 136%;
    height: 68vh;
  }

  .prices-div-home {
    margin-top: 174%;
  }
}

@media screen and (max-width: 375px) {

  .price-tag-color {
    font-size: 20px;
    font-weight: 600;
  }
}

@media screen and (max-width: 360px) {
  .price-tag-color {
    font-size: 27px;
    font-weight: 600;
  }

  .prices-div-home {
    margin-top: 180%;
  }

  .carousel-demo .product-item .product-item-content {
    padding: 9.8rem 0;
  }

  .faq-div-home {
    background-color: #bdd3fc;
    height: 110vh;
  }
}

@media screen and (max-width: 320px) {
  .price-tag-color {
    font-size: 16px;
    font-weight: 600;
  }

  .prices-div-home {
    margin-top: 171%;
  }
}


@media screen and (max-width: 315px) {
  .price-tag-color {
    font-size: 20px;
    font-weight: 600;
  }
}


@media screen and (max-width: 300px) {

  .carousel-demo {
    width: 107%;
  }

  .upper-text-carousel-left {
    top: 22%;
  }

  .upper-text-carousel-right {
    font-size: 14px;
    height: 27%;
  }

  #btn_Begin span {
    font-size: 15px;
  }

  .price-tag-color {
    color: red;
    font-size: 20px;
    font-weight: 600;
  }
}