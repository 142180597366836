.Otherservices-main-div {
  border: 1px solid white;
  background-color: rgb(232, 236, 245);

}

.Otherservices-main-title {
  text-align: center;
  margin-top: 1.5%;
  font-size: 24px;

}

.Othersevices-title1 {
  margin-left: 8%;
  margin-top: 2%;
  height: 65vh;
  position: absolute;
  width: 34%;
  text-align: center;
  background-color: white;
}

.Othersevices-title2 {
  margin-left: 50%;
  margin-right: 10%;
  margin-top: 2%;
  height: 65vh;
  position: relative;
  text-align: center;
  background-color: white;
}

.Otherservices-span-main {
  font-size: 35px;
  font-weight: 600;
  color: #112d4e;

}

.other-services-row {
  display: flex;
  padding: 1% 1% 0% 1%;
}

.other-services-col {
  border-radius: 4%;
  margin: 26px;
  background-color: #fff;
}

img.service-image {
  padding: 6% -5%;
  height: 40vh;
}

.other-services-table {
  text-align: center;
}

.services-text {

  margin-top: 2%;
  font-size: 20px;
}

span.services-text {
  text-align: center;
  font-size: 20px;
  margin: 5px;
  color: #112d4e;
}

span.services-text-description {
  text-align: center;
}

p.services-text-description-p {
  padding: 1px 30px;
  color: rebeccapurple;

}

button.p-button.p-component.services-begin-button.p-button-info {
  margin-bottom: 5%;
  border-radius: 20px;

  background: var(--ps-blue);
  border: 1px solid var(--ps-blue);
  transition: var(--ps-blue) 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

#btn_id_other_services:hover {
  background: var(--ps-blue-hover);
  border-color: #0097ff61;
}


@media screen and (max-width: 1920px) {
  .other-services-div {
    margin-top: 46%;
  }
}

@media screen and (max-width: 1770px) {
  .other-services-div {
    margin-top: 50%;
  }
}

@media screen and (max-width: 1675px) {
  .other-services-row {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    padding: 0% 1% 1% 1% !important;
    height: 100%;
  }

  .other-services-col {
    margin-bottom: 12px;
  }

  .service-image {
    width: 100%;
  }

  .img.service-image {
    padding: 0px 26px;
  }
}

@media screen and (max-width: 1536px) {
  .other-services-div {
    margin-top: 50%;
  }

  .service-image {
    width: 100%;
  }

  .img.service-image {
    padding: 0px 26px;
  }
}

@media screen and (max-width: 1508px) {
  .other-services-row {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    padding: 0% 0% 0% 0% !important;
    height: 100%;
  }

  .other-services-col {
    margin-bottom: 12px;
  }

  .service-image {
    width: 100%;
  }

  .img.service-image {
    padding: 0px 26px;
  }
}

@media screen and (max-width: 1430px) {
  .other-services-div {
    margin-top: 55%;
  }

  .other-services-row {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    padding: 1% 21% 2% 17% !important;
    height: 98%;
  }

  .other-services-col {
    margin-bottom: 12px;
  }

  .service-image {
    width: 100%;
  }

  .img.service-image {
    padding: 0px 26px;
  }

  button.p-button.p-component.services-begin-button.p-button-info {
    width: 21%;
  }

  img.service-image {
    height: 40vh;
    width: 32vw;
  }
}

@media screen and (max-width: 1386px) {
  .other-services-div {
    margin-top: 58%;
  }

  .other-services-col {
    margin-bottom: 12px;
  }

  .service-image {
    width: 100%;
  }

  .img.service-image {
    padding: 0px 26px;
  }
}

@media screen and (max-width: 1280px) {
  .other-services-div {
    margin-top: 60%;
  }

  .Otherservices-main-title {
    text-align: center;
    margin-top: 2%;
  }

  .other-services-col {
    margin-bottom: 19px;
  }

  .service-image {
    width: 100%;
  }

  .img.service-image {
    padding: 0px 26px;
  }
}

@media screen and (max-width: 1024px) {
  .other-services-div {
    margin-top: 68%;
  }

  button.p-button.p-component.services-begin-button.p-button-info {
    width: 23%;
  }
}

@media screen and (max-width: 1041px) {
  .Otherservices-main-title {
    text-align: center;
    margin-top: 2%;
  }

  .other-services-col {
    margin-bottom: 12px;
  }

  .service-image {
    width: 100%;
  }

  .img.service-image {
    padding: 0px 26px;
  }
}

@media screen and (max-width: 991px) {
  button.p-button.p-component.services-begin-button.p-button-info {
    width: 31%;
  }

  .other-services-div {
    margin-top: 72%;
  }
}

@media screen and (max-width: 960px) {
  .Otherservices-main-title {
    text-align: center;
    margin-top: 2%;
  }

  .other-services-col {
    margin-bottom: 12px;
  }

  .service-image {
    width: 100%;
  }

  .img.service-image {
    padding: 0px 26px;
  }
}

@media screen and (max-width: 918px) {
  .Otherservices-main-title {
    text-align: center;
    margin-top: 2%;
  }

  .other-services-col {
    margin-bottom: 12px;
  }

  .service-image {
    width: 100%;
  }

  .img.service-image {
    padding: 0px 26px;
  }
}

@media screen and (max-width: 912px) {
  .other-services-div {
    margin-top: 85%;
  }
}

@media screen and (max-width: 820px) {
  .other-services-div {
    margin-top: 96%;
  }
}

@media screen and (max-width: 768px) {
  .other-services-div {
    margin-top: 269%;
  }

  .Otherservices-main-title {
    text-align: center;
    margin-top: 8%;
    font-size: 24px;
  }

  .other-services-text {
    margin-top: 5%;
  }

  .other-services-row {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    padding: 5% 2% 3% 6% !important;
    height: 100%;
  }

  img.service-image {
    height: 40vh;
    width: 34vw;
  }

  .other-services-col {
    margin: 2% 10%;
  }

  .service-image {
    width: 100%;
  }

  button.p-button.p-component.services-begin-button.p-button-info {
    width: 35%;
  }
}

@media screen and (max-width: 700px) {
  .other-services-div {
    margin-top: 293%;
  }
}

@media screen and (max-width: 670px) {
  .other-services-div {
    margin-top: 298%;
  }
}

@media screen and (max-width: 610px) {
  .other-services-div {
    margin-top: 335%;
  }
}

@media screen and (max-width: 590px) {
  #btn_id_other_services {
    font-size: 12px;
  }
}


@media screen and (max-width: 550px) {
  .other-services-div {
    margin-top: 365%;
  }
}

@media screen and (max-width: 500px) {
  .other-services-div {
    margin-top: 123rem;
  }
}

@media screen and (max-width: 430px) {
  .other-services-div {
    margin-top: 118rem;
  }

  .price-tag-color {
    color: red;
    font-size: 24px;
    font-weight: 600;
  }

  button.p-button.p-component.services-begin-button.p-button-info {
    width: 55%;
  }

  img.service-image {
    height: 40vh;
    width: 44vw;
  }

  .Otherservices-main-title {
    font-size: 20px;
  }

  p.services-text-description-p {
    font-size: 13px;
  }

  .services-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 360px) {
  p.services-text-description-p {
    color: rebeccapurple;

  }

  .Otherservices-main-title {
    text-align: center;
    margin-top: 2%;
    font-size: 21px;
  }

  .Otherservices-span-main {
    font-size: 32px;
    font-weight: 600;
    color: #112d4e;
  
  }

  button.p-button.p-component.services-begin-button.p-button-info {
    width: 74%;
  }

  .other-services-text {
    margin-top: 5%;
  }

  .other-services-col {
    margin: 5% 2%;
  }

  .service-image {
    width: 100%;
  }

  .img.service-image {
    padding: 0px 26px;
  }

  img.service-image {
    height: 40vh;
    width: 54vw;
  }
}

@media screen and (max-width: 320px) {
  img.service-image {
    height: 40vh;
    width: 58vw;
  }
}

@media screen and (max-width: 280px) {
  .other-services-div {
    margin-top: 118rem;
  }
}