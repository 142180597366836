.SRU-import-sie-div {
    background-color: white;
    margin: 10px;
    text-align: end;
    margin-bottom: -65px;
    margin-top: 70px;
    width: 800px;

}


.createSRULabel {
    margin-left: 45%;
    margin-top: 5%;
    margin-bottom: -7%;
    padding: 2% 10% 0% 0%;
    font-size: 21px;
    font-weight: bold;
    color: black;
}

.SRU-import-sie-span {
    text-align: end;
    color: #fff;
}

.SRU-import-sie-span>a {
    align-items: end;
    text-align: end;
    text-decoration: none;

    font-size: 15px;
    border: 2px solid #2F4E88EE;
    background-color: #2F4E88EE;
    color: #fff;
    padding: 10px;
    border-radius: 5px;

}

.SRU-import-sie-span>a:hover {
    align-items: end;
    text-align: end;
    text-decoration: none;

    font-size: 15px;
    border: 2px solid #2196F3;
    background-color: #2196F3;
    color: #fff;
    padding: 10px;
    border-radius: 5px;

}

.dragdrop {
    border: 4px solid black;
    text-align: center;
}


#SRU-import_SIE {
    border-radius: 5px;
    border: none;
    color: #ffffff;
    text-align: center;
    padding: 15px;
    width: 200px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    width: 150px;
    height: 40px;
    background: #3F72AF;
    font-size: 1.1rem;

    position: relative;
}


.SRU-info-year-container {
    box-shadow: 5px 10px 8px #888888;
    border: 1px solid white;
    margin-top: 1%;
    width: 46%;
    margin-bottom: 12%;
    margin-left: 30%;

}

/* .card {
    background-color: white;
}

label {
    color: black;
} */

.SRU-info-col {
    background-color: white;
    margin: 7px;
    margin-left: 60px;
}

div#sru_year_dropdown {
    width: 20%;
}

div#sru_yearbtwn_dropdown {
    width: 100%;
}

.SRU-info-org-no,
.SRU-info-org-name,
.SRU-info-org-postal,
.SRU-info-org-city {
    width: 250px;
}

.SRU-info-sru-year {
    width: 100%;
}

p.info-warningLabel {
    margin-left: 10px;
    text-align: start;
    color: red;

    position: absolute;
}

.SRU-info-finance-container {
    background-color: rgb(222, 220, 220);
    box-shadow: 5px 10px 8px #888888;
    border: 1px solid white;
    width: 1300px;
}

.SRU-year-col-1 {
    padding-top: 10px;
}

.SRU-year-div {
    text-align: center;
    align-items: center;

}

.SRU-year-cal-label-container {
    width: 700px;
    margin-top: -17px;
}



.SRU-yaer-cal-label-head {
    text-align: left;
    font-size: 17px;
    margin-left: 30px;
    width: 600px;
    font-weight: bold;
}

.SRU-year-cal-label {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    font-size: 15px;

    margin-top: -5px;
    margin-left: 4%;
}

.SRU-year-cal-box {
    display: flex;
    width: 700px;
    gap: 12.2em;
    margin-left: 2.2em;

}

.SRU-year-cal-label-1 {
    margin: 10px;
    padding-top: 8px;
    width: 36%;
}

.SRU-year-cal-label-2 {
    padding-top: 16px;
    text-align: end;
    margin-left: 10%;
}

.SRU-slider-div {
    width: 30%;
    margin-left: 13%;
    margin-right: 100px;
    margin-bottom: -40px;
}



input.p-inputtext.p-component.SRU-info-org-postal {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0
}

input.p-inputtext.p-component.p-inputmask.SRU-info-org-no {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0
}

input.p-inputtext.p-component.SRU-info-org-name {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0
}

input.p-inputtext.p-component.SRU-info-org-city {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0
}

input#withoutgrouping {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0
}

input#withoutgroupingIncomplete {
    border-color: red;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0
}


button.p-button.p-component.p-datepicker-trigger.p-button-icon-only {
    background: #3F72AF;
    border-color: #3F72AF;
}

.slider-div>span {
    color: #112D4E;
    height: 4px !important;
    opacity: 40;
}

.SRU-year-btn-div {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
}

.SRU-year-btn-save {
    height: 20px;
    width: 100px;
    border: none;
    border-radius: 10px;

    font-weight: 500;
    font-size: 20px;
    color: black;
    cursor: pointer;
    position: relative;
    transition: all 0.35s;
    text-align: center;
    display: inline-block;
    transition-duration: 0.4s;
    background-color: rgb(177, 84, 34);
    margin-left: 20px;
    width: 185px !important;
    height: 50px !important;
    font-size: 16px !important;

}


button.p-button.p-component.SRU-year-btn-save:hover {
    background-color: rgb(134, 52, 8);
    color: white;
}

.p-button {
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;

    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 3px;
}

.SRU-companyInfoNextBtn {
    margin-top: 1em;
    margin-bottom: 1em;
}

.missingFieldsStyle {
    border-color: red;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    width: 90%;
}

.SruFormMultiSelect {
    width: 90%;
}

.dropdwon_selectAll {
    margin-left: -32px;
}

#checkbox_dropdown_addForm {
    margin-left: 15px;
}


.SRU-companyInformationTextBoxTitle {
    display: flex;
    padding-left: 0px;

}

.SRU-companyInformationMandatoryStart {
    color: red;
    margin-left: 3px;
    margin-top: -3px;
}


.SRU-upload-second-div {
    margin-top: 6em;
    margin-left: 31%;
    width: 51em;
}

.SRU-companyIndiImportInsideDiv {
    margin-left: 39em;

}


.App {

    text-align: center;
}



.thumb {
    width: 30px;
    height: 30px;
    border: 2px solid #3f72af;
    background-color: #fff;
    border-radius: 100%;
    transition: all 300ms ease-in-out;
}

.thumb.active {
    background-color: #3f72af;
    border: 2px solid #3f72af;
}

.track {
    position: relative;
    background: #ebe7e7;
    transition: all 300ms ease-in-out;
}

.track.track-1 {
    background-image: linear-gradient(to left, #3f72af, white);
    transition: all 300ms ease-in-out;
}

.track.track-2 {
    background: #ebe7e7;
}


.slider__labels {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 30px;
}

.slider__labels-label {
    text-align: center;
    width: 40px;
    font-weight: bold;
    box-sizing: border-box;
    font-size: 11px;
    border-radius: 5px;
}

.thumb.thumb-0.focus-visible {
    border: 2px solid #3f72af !important;
}

.SRU-companyInfoErrorToast>div>div {
    top: 5em;
    position: absolute;
}

.SIEFileErrorList {
    color: red;
}






.SRU-CIcontentParentDiv_hideDiv {
    pointer-events: none;
}


.SRU-CIcontentParentDiv {
    margin-top: -5px;
}

.SRU-import-btn-upload {
    margin-top: 24px;

    background-color: rgb(177, 84, 34);
    border: none;
}

button.p-button.p-component.SRU-import-btn-upload:hover {
    background-color: rgb(134, 52, 8);
    color: white;
}

.year-cal-label-head {
    margin-top: -18px;
    margin-bottom: 15px;


}

.p-datepicker.p-component.p-ripple-disabled {
    z-index: 1001;
    min-width: 199px;
    transform-origin: center top;
    top: 661.156px;
    left: 476.953px;
    width: 405px !important;
}

.SRU-companyInfoSideBarWithWIP {
    margin-top: 6.2em;
}

.SRU-companyInfoSideBar {
    margin-top: 4.3em;
    position: fixed;
    z-index: 10;
}

.no-access-div {
    border: 1px solid red;
    width: 500px;
    padding: 20px;

    margin-top: 300px;
    color: red;
}


.button#dropdown-split-basic {
    background: #3F72AF;
    border: none;
}

.import-sie-dropdown {
    background: #3F72AF;
    border: none;
}

.sieUploadLoadingDiv {
    height: 300px;
}

.SRU-company_fieldset1>legend,
.SRU-company_fieldset2>legend {
    background-color: rgb(242, 208, 192) !important;
}

.SRU-CIcontentParentDiv {
    margin-top: -22px;
    background: #f1f1f1;
}

.SRU-Financial-div {
    margin: 2%;
    /* border: 1px solid black; */
    padding: 0% 3%;
    margin-bottom: 3%;
}

.SRU-current-year-text-row {
    text-align: left;

}

.SRU-current-year-row {
    text-align: center;
}

.SRU-calendar-date {
    width: 100%;
}

.p-calendar.p-component.p-inputwrapper.SRU-calendar-date.p-calendar-w-btn.p-calendar-w-btn-right>button.p-button.p-component.p-datepicker-trigger.p-button-icon-only {
    background-color: rgb(177, 84, 34);
}


@media screen and (max-width: 1536px) {
    .SRU-info-year-container {
        width: 58%;
        margin-left: 24%;
    }

    .SRU-upload-second-div {
        margin-top: 6em;
        margin-left: 27%;
        width: 51em;
    }

    .createSRULabel {
        margin-left: 47%;
    }
}

@media screen and (max-width: 958px) {
    .SRU_main-container-sidebarOpenClass {
        margin-top: 0px;
    }

    .SRU-info-year-container {
        width: 59%;
        margin-left: 30%;
    }

    .SRU-companyIndiImportInsideDiv {
        margin-left: 22em;
    }
}


@-moz-document url-prefix() {
    @media screen and (max-width: 1675px) {

        .SRU-upload-second-div {
            margin-top: 6em;
            margin-left: 33%;
            width: 51em;
        }

        .SRU-info-year-container {
            width: 63%;
            margin-left: 23%;
        }


    }
}