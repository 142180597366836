.reviewAndSendPaymentSuccess {
    padding-left: 15%;
    padding-right: 7%;
    top: 21px;
    position: relative;
    margin-bottom: -65px;
}

.reviewAndSend-div {
    background: var(--surface-card);
    border-radius: 10px;
    margin-left: 15%;
    margin-right: 7%;
    margin-top: 73px;
    margin-bottom: 3.3%;
    min-height: 672px;
    top: 30px;
    position: relative;
}
.reviewAndSend-div-sidebar {
    background: var(--surface-card);
    border-radius: 10px;
    margin-left: 10%;
    margin-right: 7%;
    margin-top: 73px;
    margin-bottom: 3.3%;
    min-height: 672px;
    top: 30px;
    position: relative;
}


.review-submit {
    margin: 50px;
    padding-bottom: 50px;
}

.review-legend-template {
    font-size: 20px;
}

.upload-row {
    padding: 0% 13%;
}

.sign-rep-dropdown,
.review-rep-calendar {
    width: 100%;
}

.review-iframe-div {
    margin-bottom: 2%;
}

.go-to-income-declarion-2 {
    margin-left: 2%;
}

.review-iframe {
    width: 1300px;
    height: 1130px;
}

.review-edit {
    width: 1300px;
    margin: 0px 75px;
    margin-top: -20px;
    text-align: end;
}

@media screen and (max-width: 1536px) {
    .reviewAndSend-div {
        background: var(--surface-card);
        border-radius: 10px;
        margin-left: 20% !important;
        margin-right: 2% !important;
        margin-top: 73px;
        margin-bottom: 3.3%;
        min-height: 672px;
        top: 30px;
        position: relative;
    }
    .review-iframe {
        width: 1100px;
        height: 1030px;
        
    }
    .review-edit {
        width: 1100px;
        margin: 0px 26px !important;
        margin-top: -20px;
        text-align: end;
    }
  }

