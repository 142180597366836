.SRU-carousel-demo .SRU-product-item .SRU-product-item-content {
  border: 1px solid var(--surface-d);
  border-radius: 3px;
  text-align: center;
  padding: 0.8rem 0;
}

.SRU-first-container {
  background-size: cover;
  width: 100%;
  height: 984px;
  color: #666666;
  -webkit-font-smoothing: antialiased;
  margin-top: -96px;
}


.SRU-main-carousel-card>div>div>div>div {
  width: 100%;
}

.SRU-carousel-demo .SRU-product-item .SRU-product-image {
  width: 100%;
  height: 100%;
}


.SRU-carousel-demo .SRU-product-item .SRU-product-item-content {
  border-color: transparent;
}


#SRU-btn_Begin {
  padding: 1rem 2.2rem;
  background-color: #ff5f00;
  border: 1px solid #ff5f00;
  font-size: 25px;
  color: white;
  line-height: 1;
  border-radius: 35px;
  outline: none;
  transition: all 0.3s;
  margin: 0;
  border: none;
  cursor: pointer;
  font-size: 25px;
  font-weight: 500;
}


#SRU-btn_Begin span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

#SRU-btn_Begin span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

#SRU-btn_Begin:hover span {
  padding-right: 25px;
}

#SRU-btn_Begin:hover span:after {
  opacity: 1;
  right: 0;
}

/* .SRU-prices-div-home {
  width: 100%;
  margin-top: 48.8%;
} */

.SRU-upper-text-carousel-left {
  position: absolute;
  width: 41%;
  height: 28%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  object-fit: contain;
  top: 15%;
  margin-left: 48%;
  font-size: 29px;
  color: #3d3d3d;
}

.SRU-pageCompanyDetail {
  color: black;
}

.SRU-price-tag-color {
  color: rgb(255, 0, 0);
  font-size: 38px;
  margin-bottom: 2%;
}


.SRU-upper-text-carousel-right {
  position: absolute;
  width: 39%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1%;
  object-fit: contain;
  top: 19%;
  margin-left: 3%;
  font-size: 29px;
}


.SRU-about-div-home {
  border: 1px solid white;
  background: #b2cbf9;
  width: 100%;
  height: 98vh;
  position: absolute;
}


.SRU-faq-div-home {
  background-color: #bdd3fc;
  width: 100%;
  height: 82vh;
  margin-top: 42%;
}


@media screen and (max-width: 1920px) {
  .SRU-carousel-demo {
    margin-top: 3.7%;
  }

  .SRU-about-div-home {
    border: 1px solid white;
    background: #b2cbf9;
    width: 100%;
    height: 92vh;
    position: absolute;
  }

  .SRU-faq-div-home {
    height: 72vh;
    margin-top: 50rem;
  }

}

@media screen and (max-width: 1870px) {
  .SRU-upper-text-carousel-left {
    width: 43%;
  }

  .SRU-upper-text-carousel-right {
    width: 39%;
  }

  .SRU-faq-div-home {
    height: 78vh;
    margin-top: 47rem;
  }
}

@media screen and (max-width: 1770px) {
  .SRU-upper-text-carousel-left {
    width: 42%;
  }

  .SRU-upper-text-carousel-right {
    width: 38%;
  }
}

@media screen and (max-width: 1675px) {
  .SRU-upper-text-carousel-left {
    width: 40%;
  }

  .SRU-upper-text-carousel-right {
    width: 38%;
  }

  #SRU-btn_Begin {
    padding: 1.5rem 1.5rem;
  }
}

@media screen and (max-width: 1536px) {
  .SRU-upper-text-carousel-left {
    width: 40%;
    font-size: 25px;
  }

  .SRU-price-tag-color {
    font-size: 30px;
  }

  .SRU-upper-text-carousel-right {
    width: 39%;
  }

  .SRU-about-div-home {
    height: 100vh;
  }

  .SRU-faq-div-home {
    height: 78vh;
    margin-top: 43rem;
  }
}


@media screen and (max-width: 1500px) {
  .SRU-upper-text-carousel-left {
    width: 37%;
    font-size: 25px;
    margin-top: 4%;
  }

  .SRU-faq-div-home {
    height: 78vh;
    margin-top: 45rem;
  }
}

@media screen and (max-width: 1441px) {
  .SRU-upper-text-carousel-left {
    width: 38%;
  }

  .SRU-upper-text-carousel-right {
    width: 41%;
  }

  .SRU-faq-div-home {
    height: 84vh;
    margin-top: 50rem;
  }
}

@media screen and (max-width: 1386px) {
  .SRU-upper-text-carousel-right {
    width: 44%;
    font-size: 23px;
  }
}

@media screen and (max-width: 1350px) {

  #SRU-btn_Begin {
    top: 2%;
  }
}

@media screen and (max-width: 1300px) {
  .SRU-first-container {
    height: 825px !important;
  }

  .SRU-upper-text-carousel-left {
    width: 40%;
    margin-top: 3%;
    height: 32%;
    font-size: 23px;
  }

  .SRU-price-tag-color {
    font-size: 29px;
  }

  #SRU-btn_Begin {
    top: 1%;
    font-size: 20px;
    padding: 16px;
  }

  .SRU-upper-text-carousel-right {
    width: 44%;
    font-size: 23px;
    margin-left: 1%;
  }

}

@media screen and (max-width: 1280px) {
  .SRU-upper-text-carousel-left {
    width: 42%;
    margin-left: 46%;
    margin-top: 7%;
  }

  .SRU-upper-text-carousel-right {
    width: 42%;
    margin-top: 6%;
  }

  #SRU-btn_Begin {
    top: 1%;
    font-size: 20px;
    padding: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .SRU-upper-text-carousel-left {
    width: 42%;
    margin-left: 46%;
    margin-top: 7%;
  }

  .SRU-upper-text-carousel-right {
    width: 42%;
    margin-top: 6%;
  }

  .SRU-price-tag-color {
    font-size: 31px;
    font-weight: 600;
  }

  #SRU-btn_Begin {
    top: 1%;
    font-size: 20px;
    padding: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .SRU-carousel-demo .SRU-product-item .SRU-product-item-content {
    padding: 7.8rem 0;
  }

  .SRU-upper-text-carousel-left {
    width: 45%;
    font-size: 23px;
    padding: 4rem 0rem;
    margin-top: 10%;
    margin-left: 44%;
  }

  .SRU-upper-text-carousel-right {
    width: 45%;
    font-size: 23px;
    padding: 2rem 0rem;
    margin-top: 6%;
  }

  .SRU-price-tag-color {
    font-size: 30px;
    font-weight: 600;
  }

  #SRU-btn_Begin {
    top: 1%;
    font-size: 20px;
    padding: 23px;
  }

}

@media screen and (max-width: 958px) {

  .SRU-first-container {
    width: 100%;
    margin-bottom: -6% !important;
  }

  .SRU-upper-text-carousel-left {
    width: 49%;
  }

  #SRU-btn_Begin {
    top: 4%;
  }

  .SRU-price-tag-color {
    font-size: 30px;
    font-weight: 600;
  }

}


@media screen and (max-width: 912px) {

  .SRU-first-container {
    width: 100%;
    margin-bottom: 0% !important;
  }
}

@media screen and (max-width: 900px) {
  .SRU-carousel-demo .SRU-product-item .SRU-product-item-content {
    padding: 8.8rem 0;
    background-color: rgb(94 88 88 / 1%);
  }

  .SRU-upper-text-carousel-left {
    width: 48%;
    margin-top: 18%;
  }

  .SRU-price-tag-color {
    font-size: 26px;
    font-weight: 600;
  }
}

@media screen and (max-width: 853px) {
  .SRU-upper-text-carousel-left {
    width: 48%;
    font-size: 21px;
  }

  .SRU-upper-text-carousel-right {
    width: 55%;
    font-size: 19px;
    padding: 2rem 0rem;
    margin-top: 6%;
  }

  .SRU-faq-div-home {
    height: 100vh;
    margin-top: 47rem;
  }

}

@media screen and (max-width: 834px) {

  .SRU-carousel-demo .SRU-product-item .SRU-product-image {
    width: 128%;
    height: 100%;
  }


  .SRU-carousel-demo .SRU-product-item .SRU-product-item-content {
    padding: 7.8rem 0;
  }

  .SRU-first-container {
    width: 100%;
    margin-bottom: 0% !important;
  }

  .SRU-upper-text-carousel-left {
    width: 48%;
    font-size: 19px;
    height: 27%;
  }


  .SRU-price-tag-color {
    font-size: 26px;
    font-weight: 600;
  }
}


@media screen and (max-width: 800px) {
  .SRU-first-container {
    width: 100%;
    margin-bottom: -5% !important;
  }

  .SRU-upper-text-carousel-left {
    width: 48%;
    font-size: 19px;
    margin-top: 17%;
    margin-left: 43%;
  }

  .SRU-upper-text-carousel-right {
    width: 52%;
    font-size: 19px;
  }

}


@media screen and (max-width: 768px) {
  #SRU-btn_Begin {
    top: 1%;
    font-size: 15px;
    padding: 20px;
  }

  .SRU-faq-div-home {
    height: 125vh;
    margin-top: 51rem;
  }

}



@media screen and (max-width: 754px) {

  .SRU-carousel-demo .SRU-product-item .SRU-product-item-content {
    padding: 8.8rem 0;
  }

  .SRU-upper-text-carousel-left {
    width: 54%;
    margin-left: 36%;
    margin-top: 19%;
  }

  #SRU-btn_Begin {
    top: 1%;
    font-size: 15px;
    padding: 20px;
  }
}


@media screen and (max-width: 700px) {

  .SRU-carousel-demo .SRU-product-item .SRU-product-item-content {
    padding: 8.8rem 0;
  }

  .SRU-first-container {
    width: 100% !important;
    margin-bottom: -5% !important;
  }

  .SRU-upper-text-carousel-left {
    margin-top: 21%;
    width: 51%;
  }

  /* 
  .SRU-prices-div-home {
    margin-top: 115%;
  } */
}


@media screen and (max-width: 670px) {

  .SRU-carousel-demo .SRU-product-item .SRU-product-image {
    width: 137%;
    height: 100%;
  }

  .SRU-carousel-demo .SRU-product-item .SRU-product-item-content {
    padding: 7.8rem 0;
  }

  .SRU-first-container {
    width: 100% !important;
    margin-bottom: -10% !important;
  }

  /* .SRU-prices-div-home {
    margin-top: 120%;
  } */
}

@media screen and (max-width: 610px) {

  .SRU-carousel-demo .SRU-product-item .SRU-product-image {
    width: 137%;
    height: 65vh;
  }

  .SRU-first-container {
    width: 100% !important;
    margin-bottom: -1% !important;
  }

  .SRU-upper-text-carousel-left {
    font-size: 17px;
    margin-top: 35%;
    width: 53%;
    margin-left: 36%;
  }

  .SRU-upper-text-carousel-right {
    font-size: 17px;
    margin-top: 18%;
    width: 65%;
    padding: 18px;
  }

  /* .SRU-prices-div-home {
    margin-top: 132%;
  } */

  .SRU-price-tag-color {
    font-size: 23px;
    font-weight: 600;
  }
}



@media screen and (max-width: 590px) {
  .SRU-carousel-demo .SRU-product-item .SRU-product-image {
    width: 137%;
    height: 86vh;
  }

  .SRU-upper-text-carousel-left {
    font-size: 18px;
    margin-top: 32%;
    width: 53%;
    margin-left: 37%;
  }

  .SRU-price-tag-color {
    font-size: 22px;
    font-weight: 600;
  }


  .SRU-upper-text-carousel-right {
    width: 65%;
    font-size: 18px;
  }
}

/* @media screen and (max-width: 550px) {
  .SRU-prices-div-home {
    margin-top: 152%;
  }
} */

@media screen and (max-width: 540px) {
  .SRU-carousel-demo .SRU-product-item .SRU-product-image {
    width: 137%;
    height: 70vh;
  }

  .SRU-upper-text-carousel-left {
    font-size: 18px;
    margin-top: 35%;
    width: 56%;
    margin-left: 36%;
  }
}

/* @media screen and (max-width: 500px) {
  .SRU-prices-div-home {
    margin-top: 119%;
  }
} */


@media screen and (max-width: 430px) {
  .SRU-carousel-demo .SRU-product-item .SRU-product-image {
    width: 137%;
    height: 78vh;
  }

  .SRU-upper-text-carousel-left {
    font-size: 16px;
    margin-top: 63%;
    width: 62%;
    margin-left: 29%;
  }

  .SRU-upper-text-carousel-right {
    width: 65%;
    font-size: 15px;
  }

  .SRU-price-tag-color {
    font-size: 30px;
    font-weight: 600;
  }

  #SRU-btn_Begin span {
    font-size: 15px;
  }
}

@media screen and (max-width: 414px) {
  .SRU-prices-div-home {
    margin-top: 135%;
  }
}


@media screen and (max-width: 390px) {
  .SRU-upper-text-carousel-left {
    width: 78%;
    font-size: 15px;

    margin-left: 9%;
    margin-top: 97%;
  }

  .SRU-upper-text-carousel-right {
    width: 78%;
    font-size: 15px;

    margin-top: 41%;
  }

  .SRU-price-tag-color {
    font-size: 30px;
    font-weight: 600;
  }

  .SRU-carousel-demo .SRU-product-item .SRU-product-image {
    width: 136%;
    height: 68vh;
  }

  /* 
  .SRU-prices-div-home {
    margin-top: 141%;
  } */
}

@media screen and (max-width: 375px) {

  .SRU-upper-text-carousel-left {
    width: 84%;
    font-size: 15px;
    margin-left: 6%;
    margin-top: 102%;
  }

  .SRU-upper-text-carousel-right {
    margin-left: 11%;

    font-size: 15px;
    margin-top: 35%;
  }

  /* .SRU-price-tag-color {
    font-size: 20px;
    font-weight: 600;
  } */
}

@media screen and (max-width: 360px) {
  .SRU-upper-text-carousel-left {
    width: 84%;
    font-size: 15px;
    margin-left: 6%;
  }

  .SRU-price-tag-color {
    font-size: 27px;
    font-weight: 600;
  }

  .SRU-upper-text-carousel-right {
    width: 78%;
    font-size: 15px;
    margin-top: 41%;
  }

  .SRU-faq-div-home {
    background-color: #bdd3fc;
    width: 100%;
    height: 139vh;
  }
}

@media screen and (max-width: 320px) {
  .SRU-upper-text-carousel-left {
    font-size: 15px;
    margin-left: 6%;
    top: 24%;
  }

  .SRU-upper-text-carousel-right {

    font-size: 15px;
    top: 21%;
  }

  .SRU-price-tag-color {
    font-size: 16px;
    font-weight: 600;
  }

  .SRU-faq-div-home {
    height: 154vh;
  }

}


@media screen and (max-width: 315px) {
  .SRU-upper-text-carousel-left {
    font-size: 15px;
    margin-left: 6%;
    top: 24%;
  }

  .SRU-upper-text-carousel-right {

    font-size: 15px;
    top: 21%;
  }

  .SRU-price-tag-color {
    font-size: 20px;
    font-weight: 600;
  }

  .SRU-faq-div-home {
    height: 164vh;
  }
}


@media screen and (max-width: 300px) {

  .SRU-carousel-demo {
    width: 107%;
  }

  .SRU-upper-text-carousel-left {
    top: 22%;
  }

  .SRU-upper-text-carousel-right {
    font-size: 14px;
    height: 27%;
  }

  #SRU-btn_Begin span {
    font-size: 15px;
  }

  .SRU-price-tag-color {
    color: red;
    font-size: 20px;
    font-weight: 600;
  }
}