 .SRU-whole-delete {
     border: 1px solid red;
     background-color: red;
     color: white;
     border-radius: 5%;
     cursor: pointer;

 }

 .SRU-dashbaord-companyName {
     cursor: pointer;
 }

 .SRU_pdf-icon {
     border: 1px solid blue;
     background-color: transparent;
     border-radius: 50px;
     color: blue;
 }

 .SRU_pdf-icon:hover {
     border: 1px solid blue;
     background-color: transparent;
     border-radius: 50px;
     color: blue;
 }

 .SRU-dashboard-sharedialog {
     width: 35rem;
     text-align: center;
     max-height: 40rem;
 }

 .SRU-dashboard-share-overlap-row {
     width: 100%;
 }

 .SRU-dashboard-link-email-input-box {
     width: 200px;
 }

 .SRU-dashboard-confirmation-content {
     margin-top: 28px;
 }

 .SRU-dashboard-people-with-access {
     text-align: left;
     font-weight: 600;
     font-size: 1.1em;
     margin-bottom: 10px;
 }

 .SRU-dashboard-email-id-warning {
     text-align: left;
     color: red;
 }

 .SRU-dashboard-share-message {
     text-align: left;
     font-weight: 600;
     margin-bottom: 2%;
 }


 .SRU-dashboard-datatable1 {
     background: #ffffff;
     color: #575049;
     transition: box-shadow 0.2s;
     font-size: 14px;
     margin-top: -6px;
 }

 .SRU-dashboard-link-access-dropdown {
     text-align: end;
 }

 #SRU-dashboard-new-report-dashboard {
     margin-left: 2%;
 }

 .SRU-dashboard-main-div {
     margin-top: 5%;
 }

 .SRU-dashboard-toast-top {
     margin-top: 5%;

 }

 .SRU-myDashboardGridSideBarOpen {
     border-radius: 10px;
     margin-left: 282px;
     margin-right: 1%;
     margin-top: 90px;
     min-height: 657px;
     margin-bottom: 3.3%;
 }

 .SRU-myDashboardGridSideBarClose {
     background-color: #f1f1f1;
     border-radius: 10px;
     margin-left: 4%;
     margin-right: 2%;
     /* margin-top: 90px; */
     margin-bottom: 3.3%;
 }

 .SRU-dashboard-emai-id-overlap {
     width: 134%;
     border-radius: 7px;
 }

 .SRU-dashboard-report_share_confirm_email-textbox {
     width: 100%;
     border-radius: 7px;
 }

 .SRU-dashboard-form-control {
     width: 138%;
 }

 .SRU-dashboard-mail-give-access-dropdown {
     width: 500px;
     margin-left: -20%;
 }

 .SRU-dashboard-share-annual-text-area {
     height: 60%;
     width: 95%;
     border-radius: 7px;
     margin-left: -20px;
 }

 .SRU-dashboard-mb-4 {
     margin-bottom: 0.5rem !important;
     background: rgb(202, 173, 157) !important;
 }

 .SRU-dashboard-mail-give-access-dropdown-div {
     width: 30%;
 }