.income-statement-div {
  min-height: 530px;
}

button.p-button.p-component.toggle-open.p-button-icon-only {
  border-radius: 50px;
  margin-top: -1%;
  margin-bottom: 0%;
  margin-right: 20px;
}

.ISAmountBox {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
}

.ISAmountBoxDisabled {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  margin-top: 33px;
}

.ISAmountBoxDisabled>input {
  background-color: #ffffff;
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 0;
  margin-right: 30px;
  width: 10em;
  border-radius: 7px;
  color: black;
  font-weight: bold;
  background-color: #c7c7c79c;
  /* opacity: initial !important; */
}

.incomeStatementAmountBoxDisabled {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
}

.incomeStatementAmountBoxDisabled>input {
  background: #8080802b;
}

.ISAmountBoxCol {
  display: flex;
}

.parent {
  display: flex;
}

.ISTotalInHeading {
  margin-right: 2%;
  padding-right: 13%;
}

.ISTotalInHeading>input {
  text-align: initial;
  background: transparent;
  border: 0px solid #ced4da;
}

.ISparent {
  display: flex;
}

.ISparent-final {
  display: flex;
  margin-bottom: -7px;
}

.child {
  display: inline-block;
  column-width: 14.5em;
  text-align: center;
}

.financialYears {
  font-size: 20px;
  font-weight: bold;
  margin-left: 28px;
}

.incomeStatementHeaderStyle {
  background-color: #ededed;
  margin-bottom: 1%;
  margin-top: -38px;

  font-weight: 600;
}

.ISFromFY {
  height: 36px;
  border: solid 1px transparent;

}

.ISToFY {
  height: 36px;
  border: solid 1px transparent;
  margin-top: -16px;
  margin-left: -10px;
  width: 160px;
  margin-right: 57px;

}


span.p-accordion-header-text {
  width: 100%;
}

.ISFYStyle {
  position: fixed;
  width: 85.6%;
  margin-left: 16.4rem;
  z-index: 1;
  margin-top: 75px;
  background-color: #dfdfdf;
}

.ISFYStyle-sidebar-close {
  position: fixed;
  width: 96.8%;
  margin-left: 49px;
  margin-top: 75px;
  z-index: 1;
  background-color: #dfdfdf;
}

.ISFYStyle-sidebar-close .ISExapndCollapsToggleLbl {
  margin-left: 93%;
}

.income-accordion {
  width: 87%;
}

.ISFromFY :focus {
  box-shadow: 0 0 0 0rem #a6d5fa !important;
}

.ISToFY :focus {
  box-shadow: 0 0 0 0rem #a6d5fa !important;
}

.parentDivIncomeStatement {
  padding: 3%;
}

.ISAmountBoxCol {
  display: flex;
}

.ISFields {
  line-height: 50px;
}

.parent>div>span>input {
  height: 32px;
}

.ISSaveBtnCenter {
  margin-top: 4%;
  margin-bottom: 3.3%;
}

.ISFieldsStyle {
  font-size: 15px;
  line-height: 1.2;

}

.ISTotalFieldsStyle {

  font-size: 15px;
  line-height: 1.2;
  font-weight: 550;
  margin-top: 21px;
  margin-left: -35px;
}

.ISSaveBtn {
  width: 185px !important;
  height: 50px !important;
  font-size: 16px !important;

  background: var(--ps-blue);
  border: 1px solid var(--ps-blue);
}

.ISSaveBtn:hover {
  background: var(--ps-blue-hover);
  border: 1px solid var(--ps-blue);
}

.ISNegativeAmountBox {
  height: 36px;
  margin-right: 21px;
  box-shadow: 0px 0px 7px 1px #ff9a9a;
}

#negativeNumberWarningIcon {
  position: absolute;
  margin-top: 15px;
  margin-left: -9px;
  color: #d8d113;
}

.ISExpandCollapseLbl {
  top: -7px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  margin-left: 2px;
}

.react-switch-bg {
  background-color: #3F72AF !important;
}

#annualReportBtn {
  margin-right: 1%;
}

/* /////////////////////////////// NEW INCOME STATEMENT ///////////////////////////////////// */

.ISInputBox>input {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 7px;
  margin-right: 50px;
  width: 10em;
}

.incomeStatementFinalSumLbl {
  margin-left: 5.5%;
}

.ISFinalSumParentDiv {
  display: inline-flex;
}

.ISFinalTotalLbl {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 550;
  margin-left: 3px;
  margin-top: 16px;


}


.p-accordion-header.p-highlight.IS-Accordion-Tab .p-accordion-header-link {
  background: rgb(200 218 252) !important;

}

.p-accordion-header.IS-Accordion-Tab .p-accordion-header-link {
  background: rgb(200 218 252) !important;

}

.ISAddNewLbl {
  width: 20%;
  margin-left: 41px;
  color: green;
  font-weight: 700;
  font-style: normal;
  width: fit-content;
  border-radius: 11px;
  margin-bottom: -6px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0px;
}

.ISLblRowInsideDiv {
  width: 100%;
}

#ISLblRowInsideDivId {
  display: grid !important;
  margin-top: 7px;
  line-height: 45px;
}

.IScustomFieldText {
  margin-left: 41px;
  height: 47px;
  width: 87%;
  line-height: 21px;
  margin-bottom: 17px;
  font-size: 14px;
  border: solid 1px #ccc;
  border-radius: 10px;
  margin-bottom: -14px;
}


.ISSideBarOpen {
  border-radius: 10px;
  margin-left: 251px;
  margin-right: 0%;
  margin-top: -19px;
  min-height: 657px;
  margin-bottom: 3.3%;
}

.ISSideBarClose {
  border-radius: 10px;
  margin-left: 2%;
  margin-right: 0%;
  margin-top: -37px;
  margin-bottom: 3.3%;
}

#ISCustomRowDeleteOpenSideBar {
  font-size: 17px;
  margin: 12px;
  margin-left: -46px;
  color: #af3f3f;
  cursor: pointer;
  margin-top: 15px;
}

#ISCustomRowDeleteCloseSideBar {
  margin: 12px;
  margin-left: -44px;
  color: #af3f3f;
  cursor: pointer;
  font-size: 17px;
  margin-top: 15px;
}

.ISParentRow {
  width: 99%;
  margin-left: 0%;
  margin-top: 9%;

}

@media screen and (max-width: 1675px) {
  .ISParentRow {
    margin-left: 2%;
  }

  .ISFYStyle {
    width: 77.8%;
    margin-left: 20%;
  }


  .ISFYStyle .ISExapndCollapsToggleLbl {
    margin-left: 74.5%;
  }
}

@media screen and (max-width: 1508px) {
  .ISParentRow {
    margin-left: 3%;
    margin-top: 10.5%;
  }

  .ISSideBarOpen {
    margin-right: -1%;
  }

  .ISFYStyle {
    width: 76.5%;
    margin-left: 22%;
  }

  .ISFYStyle .ISExapndCollapsToggleLbl {
    margin-left: 72.5%;
  }
}

@media screen and (max-width: 1536px) {
  .ISParentRow {
    margin-left: -1%;
    margin-top: 11%;
    width: 100%;
  }

  .ISFYStyle {
    width: 80.4%;
    margin-left: 273px;
  }

  .ISFYStyle .ISExapndCollapsToggleLbl {
    margin-left: 75.5%;
  }

  .ISSideBarOpen {
    border-radius: 10px;
    margin-left: 275px;
    margin-top: -0.5em;
    margin-bottom: 3.3%;
  }

  .ISSideBarClose {
    border-radius: 10px;
    margin-left: 3.5%;
    margin-right: 2%;
    margin-top: -33px;
    margin-bottom: 3.3%;
    width: 97.5%;
  }

  .ISFYStyle-sidebar-close {
    position: fixed;
    width: 95.9%;
    margin-left: 51px;
    margin-top: 75px;
    z-index: 1;
    background-color: #dfdfdf;
  }

  .ISFYStyle-sidebar-close .ISExapndCollapsToggleLbl {
    margin-left: 91%;
  }

}


@media screen and (max-width: 1441px) {
  .ISFYStyle {
    width: 75.7%;
    margin-left: 22.8%;
  }

}



@media screen and (max-width: 1386px) {
  .ISParentRow {
    margin-left: 5%;
    width: 114%;
    margin-top: 11.1%;
  }

  .ISExapndCollapsToggleLbl {
    margin-bottom: -18px;
    margin-top: 26px;
  }

  .ISFYStyle {
    width: 76.2%;
    margin-left: 22.4%;
    margin-top: 4.2%;
  }

}

@media screen and (max-width: 1225px) {
  .ISParentRow {
    margin-left: 3%;
    width: 126%;
  }

  .ISSideBarOpen {
    margin-left: 19%;
  }
}

@media screen and (max-width: 1111px) {
  .ISParentRow {
    margin-left: 3%;
    width: 144%;
  }

  .ISSideBarOpen {
    margin-left: 21%;
  }

  .ISExapndCollapsToggleLbl {
    margin-bottom: -18px;
    margin-top: 26px;
  }
}

@media screen and (max-width: 1041px) {
  .ISParentRow {
    margin-left: 3%;
    width: 152%;
  }

  .ISSideBarOpen {
    margin-left: 23%;
  }

}

#isFirstLineSum {
  margin-top: 26px;
}

@media screen and (max-width: 960px) {
  .ISParentRow {
    width: 173%;
  }

  .ISSideBarOpen {
    margin-left: 25%;
  }
}

@media screen and (max-width: 860px) {
  .ISParentRow {
    margin-left: 3%;
    width: 187%;
  }

  .ISInputBox>input {
    width: 100%;
  }

  .ISAmountBoxDisabled>input {
    width: 100%;
  }

  #ISCustomRowDeleteOpenSideBar {
    margin-left: 69%;
    position: absolute;
  }

  #ISCustomRowDeleteCloseSideBar {
    margin-left: 64%;
    position: absolute;
  }

  .ISSideBarOpen {
    margin-left: 29% !important;
  }
}

@media screen and (max-width: 734px) {

  .ISFromFY>input,
  .ISToFY>input {
    margin-left: -3px;
    font-size: 13px;
  }

  .ISParentRow {
    margin-left: 30%;
  }

  #ISCustomRowDeleteCloseSideBar {
    margin-left: 86%;
    position: absolute;
  }
}


.ISExapndCollapsToggleLbl {
  text-align: end;
  position: fixed;
  margin-top: 8px;
  margin-left: 81.5%;
}


.ISLoading {
  text-align: center;
  padding: 21em;
}

.Page_Title_T1_incomestatement {
  margin-left: 4px;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  /* color: #3F72AF ! important; */
}

.incomeStatementSideBar {
  margin-top: 71px;
  position: fixed;
}

.ISFinalLineSum>input {
  /* background-color: #ffffff; */
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 0;
  margin-right: 30px;
  width: 10em;
  border-radius: 7px;
  /* color: black; */
  font-weight: bold;
  border: 1px solid #f1f1f1;
  opacity: initial !important;
  background-color: #c7c7c79c;
}

.ISFinalSumDiv {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  margin-top: 11px;
  margin-left: 3px;
}

.ISAddNewLine {
  cursor: pointer;
  color: #104ca5bf;
  font-size: 11px;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  display: inline-block;
  margin-left: -49px;
  top: 15px;
  position: relative;
}

.ISAddPlus {
  margin-top: -17px;
  margin-left: 4px;
  color: white;
  font-size: 13px;
}

#ISAddNewLineIcon,
#ISAddNewLineIcon123 {
  color: #104ca5bf;
  margin-left: -46px;
  position: relative;
  font-size: small;
  cursor: pointer;
}


#ISAddNewLineIconBelowDesc {
  color: #104ca5bf;
  margin-left: 25px;
  top: -22px;
  position: relative;
  cursor: pointer;
  display: flex;
}

.ISFYMainDiv {
  display: grid;
}

.Page_Title_incomeStatement {
  margin-top: 0.5%;
  margin-bottom: 19px;

}

button.p-button.p-component.pdf_view_incomestatement {
  margin-left: -265%;
  font-size: 14px;
  margin-top: 9px;
}

.ISAmountBoxDisabled>input,
.ISInputBox>input,
.ISFinalLineSum>input {
  text-align: right;
}

.ISLblRowMainsideDiv>div {
  margin-left: 35px;
}

.ISLblRowMainsideDiv>div>input {
  border: 1px solid #dadada;
  font-size: 15px;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
}

.ISLblRowMainsideDiv>div>div {
  z-index: 10;
}

.suggestionItemRender {
  border: 2px solid;
  border-radius: 7px;
}


@-moz-document url-prefix() {
  .ISFinalSumDiv {
    padding-right: 54% !important;
  }
}

@-moz-document url-prefix() {
  @media screen and (max-width: 1675px) {
    .ISFYStyle {
      width: 80.5%;
      margin-left: 17.1%;
    }

    .ISParentRow {
      margin-top: 10.9%;
      margin-left: -2%;
    }

    .ISFYStyle .ISExapndCollapsToggleLbl {
      margin-left: 76.5%;
    }

    .ISFYStyle-sidebar-close {
      width: 95.8%;
      margin-left: 52px;
      margin-top: 75px;
    }

    .ISSideBarClose {
      margin-left: 70px;
      margin-top: -33px;
    }

    .ISFYStyle-sidebar-close .ISExapndCollapsToggleLbl {
      margin-left: 91%;
    }

  }

  .ISFinalSumDiv {
    padding-right: 54% !important;
  }
}