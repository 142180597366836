.SRU-companyInfoErrorToast>div>div {
    top: 5em;
    position: absolute;
}

.SRU-companyInfoSideBarWithWIP {
    margin-top: 6.2em;
}

.SRU-companyInfoSideBar {
    margin-top: 4.3em;
    position: fixed;
    z-index: 10;
}

.SRU-ISAmountBoxDisabled {
    text-align-last: right;

}


.SRU-INK2FieldsStyle {
    font-size: 15px;
    line-height: 1.2;

}

.SRU-INK2FYStyle {
    position: fixed;
    width: 86.7%;
    margin-left: 15.2rem;
    z-index: 1;
    margin-top: 75px;
    background-color: #dfdfdf;
    height: 8%;
}

.SRU-INK2FYStyle-sidebar-close {
    position: fixed;
    width: 94.5%;
    margin-left: 90px;
    margin-top: 75px;
    z-index: 1;
    background-color: #dfdfdf;
    height: 8%;
}

.SRU-INK2FYStyle-sidebar-close .SRU-INK2ExapndCollapsToggleLbl {
    margin-left: 90%;
}

.SRU-INK2FYStyle-sidebar-close .SRU-INK2ExapndCollapsToggleLbl {
    margin-left: 0%
}

.SRU-INK2parent {
    display: flex;
}

.SRU-INK2parent-final {
    display: flex;
    margin-bottom: -7px;
}

.SRU-INK2ParentRow {
    width: 100%;
    margin-left: 0%;
}

.SRU-Page_Title_INK2 {
    margin-top: 0.5%;
    margin-bottom: 19px;

}

.SRU-Page_Title_T1_INK2 {

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    color: rgb(177, 84, 34) !important;
}

.SRU-ISAmountBoxCol {
    display: flex;
}

.SRU-INK2AmountBoxCol {
    display: flex;
}

.SRU-INK2FYMainDiv {
    font-size: 17px;
    font-style: normal;
    font-weight: 00;
    margin-top: 10px;
    color: black;
    font-weight: 550;
}

.SRU-toggle-open {
    margin-top: 10px;
    border-radius: 20px;
}


.SRU-INK2SideBarOpen {
    border-radius: 10px;
    margin-left: 12.5%;
    margin-top: 9.4em;
    min-height: 657px;
    margin-bottom: 3.3%;
}

.SRU-INK2SideBarClose {
    border-radius: 10px;
    margin-left: 4%;
    margin-top: 9.4em;
    margin-bottom: 3.3%;
}


.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    width: 70px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -30px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
}


.tooltip-icon {
    font-size: 1.2em;
}


.tooltip-container:hover .tooltip-text,
.tooltip-container.show .tooltip-text {
    visibility: visible;
    opacity: 1;
}


.p-accordion-header.p-highlight.SRU-INK2-accordion-Tab>.p-accordion-header-link>span.p-accordion-header-text {
    color: black;
}

.p-accordion-header.p-highlight.SRU-INK2-accordion-Tab>.p-accordion-header-link {
    background: rgb(202, 173, 157) !important;
}

.p-accordion-header.SRU-INK2-accordion-Tab>.p-accordion-header-link {
    background: rgb(202, 173, 157) !important;
}

.SRU-toggle-open {
    background-color: rgb(177, 84, 34);
}

.ink2-textArea {
    width: 970px;
    margin-left: 10%;
}

.second_div_input {
    border: 1px solid red;
    width: 25%;
}

@media screen and (max-width: 1536px) {

    .SRU-Page_Title_T1_INK2 {
        font-size: 17px;
    }

    .SRU-INK2FYMainDiv {
        font-size: 15px;
        margin-top: 0px;
    }

    .SRU-INK2FYStyle {
        position: fixed;
        width: 82.7%;
        margin-left: 16.2rem;
        z-index: 1;
        margin-top: 74px;
        background-color: #dfdfdf;
        height: 9%;
    }


    .SRU-INK2FYStyle-sidebar-close {
        position: fixed;
        width: 94%;
        margin-left: 73px;
        margin-top: 75px;
        height: 9%;
    }

    .SRU-INK2SideBarClose {
        margin-top: 8.5em;
        margin-right: 3%;
    }

    .second_ink2_div {
        margin-left: 5%;
    }

    .SRU-INK2ParentRow {
        width: 103%;
    }

    .ink2-textArea {
        width: 200%;
    }

    .SRU-ISFields {
        line-height: 20px;
        width: 28rem;
        margin-bottom: 12px;
    }

    .SRU-INK2SideBarOpen {
        border-radius: 10px;
        margin-left: 248px;
        margin-right: 3%;
        margin-top: 8.3em;
        min-height: 657px;
        margin-bottom: 3.3%;
        width: 81.2%;
    }
}