.Common-MainsideBarNotAccessableToast {
    top: 83%;
    right: 20px;
}

.Common-main-container-sidebarOpenClass {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    position: fixed;
    float: right;
    overflow: hidden;
    height: 100vh;
    margin-top: 3.5%;
}

.Common-main-container-sidebarCloseClass {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    position: fixed;
    float: right;
    overflow: hidden;
    height: 100%;
    margin-top: 0.3em;
    margin-top: 3.5%;
}

.Common-MainsidebarExtent {
    background: rgb(248, 247, 247);
    color: white;
    height: 123vw;
    overflow-y: auto;
    min-height: 40vw;
    max-height: 130vw;
}

.Common-Mainsidebar {
    background: rgb(248, 247, 247);
    color: white;
    margin-top: 2%;
    overflow-y: auto;
}

.Common-MainSidebar_top_section {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 3%;
}

p.subtext-annual-report {
    color: black;
    margin: 0px;
    position: relative;
    margin-right: 39px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 16px;
}

.bars {
    width: 30px;
    margin-top: 11px;
}

.routes {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.sidebarActive {
    display: flex;
    color: rgb(255 255 255);
    gap: 10px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    text-decoration: none;
    background: var(--ps-blue);
}

.commonLink {
    display: flex;
    color: rgb(0, 0, 0);
    gap: 10px;
    padding: 4px 10px;
    border-right: 4px solid transparent;
    transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    text-decoration: none;
}

.link:active {
    box-shadow: 0 0 20px rgba(0, 0, 0.5);
}

.icon {
    font-size: 25px;
    color: red;
}

.menu-names-link {
    padding-top: 9px;
}

.tickIcon {
    width: 22px;
    position: absolute;
    margin-left: -10px;
}



@media screen and (max-width: 1920px) {
    /* .Common-main-container-sidebarOpenClass {
        height: 52.4%;
    }

    .Common-main-container-sidebarCloseClass {
        height: 52.2%;
    }

    .Common-Mainsidebar {
        height: 95.2%;
    } */
}

@media screen and (max-width: 1536px) {
    .Common-main-container-sidebarOpenClass {
        margin-top: 4.5%;
    }

    .Common-main-container-sidebarCloseClass {
        margin-top: 4.5%;
    }
}

@media screen and (max-width: 960px) {
    .Common-main-container-sidebarOpenClass {
        width: 217px;
        margin-top: 7%;
    }

    .Common-main-container-sidebarCloseClass {
        margin-top: 6.5%;
    }
}

@media screen and (max-width: 890px) {
    .Common-main-container-sidebarOpenClass {
        width: 217px;
    }

}

@media screen and (max-width: 850px) {
    .Common-main-container-sidebarOpenClass {
        width: 217px;
    }

    .Common-main-container-sidebarCloseClass {
        margin-top: 7%;
    }
}


@media screen and (max-width: 750px) {

    .Common-main-container-sidebarCloseClass {
        margin-top: 9%;
    }
}