.admin-page-total-content {
    margin-top: 5%;
    margin-bottom: 5%;
    min-height: 40em;
}

.AdminContent {
    margin-left: 23%;
    margin-top: 2%;
    font-size: 18px;
    font-weight: bolder;
}

.toast-top {
    margin-top: 5%;
}

.AdminSettingPage_Main {
    margin-left: 15%;
    margin-right: 1%;
}

.admin-accordion {
    width: 80%;
    margin-left: 10%;

}

.admin-accordion-close {
    width: 70%;
    margin-left: 20%;

}

.card-admin-page {
    width: 100%;
    margin-bottom: 3%;
}

.admin-toolbar {
    padding: 0.5% 3%;
}

.Acc-Company-Info {
    width: 32%;
}

button.p-button.p-component.edit-button-admin-page.p-button-icon-only.p-button-outlined.p-button-rounded {
    margin-left: 0rem;
}

.editbtnadminsetting {
    width: 3rem;
}

.edit-btn-col {
    text-align: end;
    margin-top: -3%;
}

.react-switch-bg {
    background-color: #3F72AF !important;
}

.ISActiveCollapseLbl {
    position: relative;
    font-size: 14px;
    font-weight: 600;
}

.p-column-filter-add-rule {
    display: none;
}

.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled.p-column-filter-matchmode-dropdown {
    display: none;
}

.adminPageCompanyInfoMainDiv {
    width: 50%;
    margin-left: 5%;
}

.adminPageCompanyInfoKey {
    text-align: center;
}

.adminPageCompanyInforTitle {
    width: 152%;
    font-size: 110%;
    line-height: 44px;
    margin-top: 7px;
}

.adminPageCompanyInfoTitleRow {
    line-height: 55px;
    margin-bottom: 14px;
}

.adminPageCompanyInfoEditPopUpTitles {
    margin-bottom: 0px;
    font-weight: 600;
}

.field {
    margin-bottom: -14px;
}

.adminPageEmailExistsAlready {
    color: red;
}

.adminPageUserListLabelCntr {
    background: #dbe2ef52;
    font-size: larger;
}

.adminPageUserListLabel {
    color: #3f72af;
}

.Admin-toggle-open {
    text-align: center;
    margin-left: 30%;
}

.admin-setting-sidebar {
    position: fixed;
    margin-top: 3.6%;
}

.admin-restriction {
    padding: 280px 0px;
    font-size: 22px;
}