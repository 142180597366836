.footer--bottom {
  background-color: #394150;
  color: rgb(255, 255, 255);
  padding-top: 40px;
  text-align: center;
}

.footer--bottom--sidebarOpen {
  margin-left: 10%;
  background-color: #394150;
  color: rgb(255, 255, 255);
  padding-top: 40px;
  text-align: center;
}

.Footercontainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 100em;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.footer--bottom .Footercontainer {
  box-sizing: border-box;
}

.footer--bottom__col {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2%;
}

.footer--bottom__col_with__sidebar {
  margin-left: 10%;
  margin-right: 11%;
}

.footer--bottom__cell--links {
  padding: 40px;
}

.footer--bottom__cell--links_with__sidebar {
  padding: 40px;
  margin-left: 40px;
}


.footer--bottom__cell--links {
  border-right: 0.5px solid white;
  display: flex;
  flex-basis: 1 100%;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0.9375em;
  padding-bottom: 0.9375em;
  text-align: center;
}

.footer--bottom__cell--icons,
.footer--bottom__cell--icons_with__sidebar {
  display: flex;
  flex-basis: 1 100%;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 34px;
  padding-left: 20px;
  padding-left: 0px;
  text-align: left;
  text-align: center;
  width: 30%;
}

.footer--bottom__cell--icons .footer--bottom__cell--links {
  padding: 0 4.625em;
}

.footer--bottom__cell--icons_with__sidebar {
  padding: 0px 1.125em 0px 1.315em;
}

.footer--bottom__cell--icons h3,
.footer--bottom__cell--icons_with__sidebar h3 {
  color: rgb(241, 244, 255);
  font-size: 1.75em;
  font-weight: 500;
  line-height: 1.4em;
  margin-top: 0;
  text-transform: uppercase;
  margin-left: -142px;
}

.footer--bottom__cell--sub-links {
  display: contents;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: 5em;
}

.tw-h-10 {
  height: 4.5rem;
  margin-bottom: 8%;
}

.footer--bottom_sub {
  color: rgb(241, 244, 255);
  list-style: none;
}

.footer--bottom_sub:hover {
  color: #bdd3fc;
}

.footer--bottom__ul {
  list-style: none;
  text-align: left;
}

.footer--bottom__icon__container {
  margin-left: 80px;
}

.footer--bottom a {
  font-weight: 500;
  text-decoration: none;
}

.tw-mb-4 {
  cursor: default;
}

.footer_title1 {
  cursor: default;
}

.footer_title2 {
  cursor: default;
}

.footer_title3 {
  cursor: default;
}

input.p-inputtext.p-component.news-text-box {
  width: 280px;
  margin-right: 10px;
}


.footer-subscription-text {
  margin-top: 14px;
  margin-bottom: 0rem;
  cursor: default;
}

.footer--bottom--form,
.footer--bottom--form_with__sidebar {
  padding: 1rem 1rem;
  overflow: hidden;
  position: relative;
}

.terms-row {
  padding: 2px;
  width: 98.5vw;
  background: black;
  color: white;
  cursor: pointer;
}

.terms-row_with__sidebar {
  padding: 1%;
  width: 89vw;
  background: black;
  color: white;
  cursor: pointer;
}

.footer-bottom--terms {
  color: #fff;
}

.footer-bottom--conditions {
  color: #fff;
}

.footer-bottom--cookies {
  color: #fff;
}

.footer-bottom--companyname {
  color: #fff;
}

.newsletter-Email-User {
  width: 300px;
  height: 40px;
  border-radius: 10px;
  border-color: #fff;
  border-style: none;
}

#footer--bottom-__cell--last--contact {
  border-right: 0px solid black;
  padding: 21px 0px;
}


.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid black;
  width: 35px;
  height: 35px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  margin-left: 85px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.news-letter-toast {
  margin-bottom: 250px;
}

.footer-li-style {
  font-weight: 100;
  text-decoration: none;
}

#footer_email_contact_text {
  color: white;
  font-style: italic;
}

#footer_email_contact_text:hover {
  color: white;
  font-weight: 600;
}

@media screen and (max-width: 1920px) {
  .newsletter-Email-User {
    width: 230px;
    height: 40px;
    border-radius: 10px;
    border-color: #fff;
    border-style: none;
    margin-left: 35%;
  }

  button.p-button.p-component.news-button.p-button-icon-only.p-button-outlined {
    color: white;
    margin-left: 85px;
  }
}

@media screen and (max-width: 1675px) {

  .footer--bottom__cell--icons_with__sidebar,
  .footer--bottom__cell--links_with__sidebar {
    padding: 0px 1.125em 0px 0.315em;
  }
}

@media screen and (max-width: 1550px) {
  .footer--bottom__cell--icons {
    padding: 0 2.625em;
  }

  .footer--bottom__cell--links {
    border-right: none;
  }

  .footer--bottom--form,
  .footer--bottom--form_with__sidebar {
    padding: 0rem 0rem;
  }

  .footer--bottom--form .footer--bottom__cell--icons_with__sideba {
    padding: 0px 0.125em 0px 0.315em;
  }

  .footer--bottom__cell--links {
    border-right: 0.5px solid white;
  }
}

@media screen and (max-width: 1450px) {
  .footer--bottom__cell--icons {
    padding: 0 0.625em;
  }

  .footer_title1 .footer_title2 .footer_title3 {
    cursor: default;
    font-size: 24px;
  }

  section {
    font-size: 14px;
  }
}

@media screen and (max-width: 1280px) {
  .footer--bottom__cell--icons h3 {
    margin-left: -81px;
  }

  .newsletter-Email-User {
    width: 214px;
    margin-left: 30%;
  }

  button.p-button.p-component.news-button.p-button-icon-only.p-button-outlined {
    color: white;
    margin-left: 75px;
  }


}

@media screen and (max-width: 1200px) {
  .footer--bottom__cell--icons h3 {
    margin-left: -81px;
  }

  .newsletter-Email-User {
    width: 180px;
    margin-left: 30%;
  }

}

@media screen and (max-width: 1100px) {
  .newsletter-Email-User {
    width: 190px;
  }

  .footer--bottom__cell--links {
    padding: 0 3.625em;
  }
}

@media screen and (max-width: 1024px) {
  .footer--bottom__cell--icons h3 {
    margin-left: -75px;
  }

  .newsletter-Email-User {
    width: 190px;
  }

  .footer_Logo_image {
    width: 270px;
  }
}

@media screen and (max-width: 958px) {
  .newsletter-Email-User {
    width: 176px;
    margin-left: 6%;
  }

  .footer--bottom--form {
    margin-left: 15%;
    z-index: 0;
  }

  button.p-button.p-component.news-button.p-button-icon-only.p-button-outlined {
    color: white;
    margin-left: 26px;
  }
}

@media screen and (max-width: 900px) {
  .newsletter-Email-User {
    width: 190px;
    margin-left: 7%;
  }

  .footer--bottom__cell--links {
    padding: 0 0.625em;
  }
}

@media screen and (max-width: 830px) {
  .footer--bottom__cell--icons {
    padding: 13px 0px 3px 2px;
  }

  .footer--bottom__cell--sub-links {
    font-size: small;
  }

  .footer_Logo_image {
    width: 260px;
  }
}

@media screen and (max-width: 768px) {
  .newsletter-Email-User {
    width: 175px;
  }

  .footer--bottom__cell--sub-links {
    font-size: small;
  }
}

@media screen and (max-width: 600px) {
  .newsletter-Email-User {
    width: 175px;
  }

  .footer--bottom__cell--icons {
    padding: 1px 0px 3px 2px;
  }

  .footer--bottom__cell--sub-links {
    font-size: small;
  }

  .footer--searchbox-wrap {
    margin-left: -25%;
  }

  .footer--bottom--form {
    margin-left: 15%;
    z-index: 0;
  }

  .footer--bottom__cell--links {
    border-right: 0px solid white;
  }
}

@media screen and (max-width: 590px) {
  .footer--bottom--form {
    margin-left: 32%;
    z-index: 0;
  }

  .footer_Logo_image {
    width: 250px;
  }
}

@media screen and (max-width: 540px) {
  .newsletter-Email-User {
    width: 175px;
  }

  .footer--bottom__cell--icons {
    width: 54%;
  }

  .footer--bottom__cell--sub-links {
    font-size: small;
  }
}

@media screen and (max-width: 520px) {
  .footer--searchbox-wrap {
    margin-left: 0%;
  }
}

@media screen and (max-width: 500px) {
  .footer--bottom--form {
    margin-left: 17%;
    z-index: 0;
  }
}

@media screen and (max-width: 412px) {
  .newsletter-Email-User {
    width: 160px;
  }

  .footer--bottom__cell--icons {
    width: 54%;
  }

  .footer--bottom__cell--icons h3 {
    margin-left: -23px;
  }

  .footer-li-style {
    margin-right: 3%;
  }

  .footer--bottom__cell--sub-links {
    font-size: small;
  }

  .footer_Logo_image {
    width: 200px;
  }
}

@media screen and (max-width: 390px) {
  .footer--bottom--form {
    margin-left: 17%;
    z-index: 0;
  }

  .newsletter-Email-User {
    width: 201px;
  }
}

@media screen and (max-width: 375px) {
  .newsletter-Email-User {
    width: 178px;
  }
}

@media screen and (max-width: 340px) {
  .newsletter-Email-User {
    width: 170px;
    height: 30px;
  }
}


@media screen and (max-width: 315px) {
  .footer-subscription-text {
    margin-left: -31px;
    margin-top: 5px;
    margin-bottom: 0rem;
  }

  .newsletter-Email-User {
    width: 160px;
    height: 35px;
  }

  .footer--bottom__cell--icons {
    width: 100%;
  }

  .footer--bottom__cell--sub-links {
    font-size: x-small;
  }

  .footer_image_logo_main {
    width: 202px;
  }
}

@media screen and (max-width: 280px) {
  .newsletter-Email-User {
    height: 34px;
    margin-top: 1px;
    margin-left: 0%;
  }

  .footer--bottom__cell--icons {
    width: 100%;
  }
}

.footerSection {
  list-style-type: none;
}

.footer-main-div {
  background-color: #394150;
  color: white;
}

.footer-main-div-sidebar {
  background-color: #394150;
  color: white;
  margin-left: 13%;
}

.footer-image-div {
  padding-top: 40px;
}

.footer-list-div {
  margin: 40px 40px;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  margin-top: 3%;
}

.footer_title1 {
  font-size: 1.75rem;
}

.footer-list-div-1 {
  border-right: 0.5px solid white;
padding: 40px;
}

.footer-black-bottom {
  background-color: black;
  color: white;
  padding-top: 1px;
  padding-bottom: 4px;
}

.divider {
  width: 1px;
  background-color: white;
  height: 100%;
}