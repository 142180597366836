.warning {
    height: 40px;
    width: 250px;
    position: absolute;
    right: 0;
    top: 10%;
    background-color: rgb(210, 91, 91);
    font-size: 1.1rem;
    color: white;
    font-family: cursive;
    padding: 5px;
    border-radius: 5px;
}


.SRU-section-prices {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    margin-left: 12%;
}

.SRU-section-class {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.details p:nth-child(1) {
    font-weight: bold;
}

.details p:nth-child(2) {
    color: grey;
    font-size: 0.8rem;
}

.details p:nth-child(3) {
    font-weight: bold;
    color: grey;
}

.details button {
    padding: 5px 10px;
    border: none;
    font-weight: bold;
    background-color: red;
    outline: none;
    border-radius: 2px;
}


.demo {
    padding: 20px 0;

}

.SRU-pricesMainDiv {
    margin-top: 2%;
}

.heading-title {
    margin-bottom: 25px;
    color: black;
    font-weight: 700;
    margin-top: 1%;
}

.SRU-pricingTable {
    border: 1px solid #dbdbdb;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    margin: 0% 12%;
    margin-top: 12%;
    text-align: center;
    transition: all 0.4s ease-in-out 0s;
}

.SRU-pricingTable:hover {
    border: 2px solid #3F72AF;
}

.SRU-pricingTable .SRU-pricingTable-header {
    padding: 22px 10px;
}

.SRU-pricingTable .heading {
    display: block;
    color: #000;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 21px;
}

.SRU-pricingTable .SRU-pricing-plans {
    padding-bottom: 25px;
    border-bottom: 1px solid #d0d0d0;
    color: #000;
    font-weight: 900;

}

.SRU-pricingTable .SRU-price-value {
    color: #474747;
    display: block;
    font-size: 25px;
    font-weight: 800;
    line-height: 15px;
    padding: 0 10px;
}

.SRU-pricingTable .SRU-price-value span {
    font-size: 20px;
}

.SRU-pricingTable .subtitle {
    color: #82919f;
    display: block;
    font-size: 15px;
    margin-top: 15px;
    font-weight: 100;
}

.SRU-pricingTable .SRU-pricingContent ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
}


.SRU-pricingTable .SRU-pricingContent ul li:last-child {
    border-bottom: 1px solid #dbdbdb;
}

.SRU-pricingTable .SRU-pricingTable-sign-up {
    padding: 15px 0;
  
}

.SRU-pricingTable .btn-block {
    width: 50%;
    margin: 0 auto;
    background: #3F72AF;
    border: 1px solid transparent;
    padding: 10px 5px;
    color: #fff;
    text-transform: capitalize;
    border-radius: 5px;
    /* transition:0.3s ease; */
}

.SRU-pricingTable .btn-block:after {
    padding-left: 10px;
    font-size: 15px;
}

.my_shop {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    font-family: cursive;
    cursor: pointer;
}

.cart {
    cursor: pointer;

}


.cart span:nth-child(2) {
    padding: 0 3px;
    background-color: red;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    position: relative;
    top: -14px;
}

.cart-trash-icon {
    background-color: red;
    color: white;
}

svg.shopping-cart {
    font-size: 30px;
    /* margin-top: 7px; */
    margin-top: 0px;
    margin-right: 0px;
}

.col-md-4 {
    width: 100%;
}

.cart-col-2 {
    width: 140px;
    margin-top: -2.8%;
}

button.p-button.p-component.cart-item-amount.p-button-text {
    border: 1px solid black;
    color: black;
}

.cart-item-multiply {
    margin-top: -3px;
}

.cart-col-3>p {
    margin-top: -3px;
    margin-left: -5px;
    margin-right: 8px;
}

.SRU-pricesIterationDiv {
    width: 66%;
  
    margin-left: 19%;
}

.SRU-prices-main-title {
    text-align: center;
    font-size: 24px;
}

.SRU-prices-span-main {
    font-size: 35px;
    font-weight: 600;
    color: #112D4E;

}

.extraUser-main-title {
    text-align: center;
    font-size: 24px;
    margin-top: 5%;
}

.extraUser-span-main {
    font-size: 35px;
    font-weight: 600;
    color: #112D4E;

}

.p-button .p-button-label {
  
}

.extraUserCntr {
    margin-top: 1%;
}

.extraUserDiv {
    display: inline-flex;
}

.extraPriceAmount {
    margin-top: 10px;
    margin-left: 20px;
    font-weight: 600;
    color: #112D4E;
  
}

.extraPriceValidity {
    margin-top: 17px;
    margin-left: 20px;
    font-weight: 600;
    color: #112D4E;

}

.extraPriceApplicable {
    margin-top: 17px;
    margin-left: 20px;
    font-weight: 600;
    color: #3f72af;
  
}

.login-in-prices {
    display: none;
}

@media screen and (max-width: 1920px) {
    .SRU-section-prices {
        width: 76%;
    }

    .SRU-pricingTable {
        margin-top: 12%;
    }

    .SRU-pricingTable .SRU-pricingContent ul li {
        padding: 12px 0;
    }
}

@media screen and (max-width: 1536px) {

    .SRU-prices-main-title-div {
        margin-top: 2%;
    }

    .SRU-section-prices {
        margin-top: -7%;
    }

    .SRU-pricingTable {
        margin-top: 10%;
    }

    .SRU-pricingTable .heading {
        font-size: 15px;
    }

    .SRU-pricingTable .SRU-pricingContent ul li {
        padding: 8px 0;
    }

    .SRU-prices-main-title {
        margin-bottom: 6%;
    }
}



@media screen and (max-width: 768px) {
    .SRU-pricingTable .SRU-pricingContent ul li {
        padding: 4px 0;
    }

    .SRU-section-prices {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .SRU-pricingTable {
        margin: 4% 18%;
    }

    .SRU-prices-main-title {
        margin-bottom: 12%;
    }
}

@media screen and (max-width: 500px) {
    .SRU-section-prices {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 430px) {
    .SRU-pricingTable {
        margin: 4% 18%;
    }
}

@media screen and (max-width: 375px) {
    .SRU-pricingTable .btn-block {
        width: 60%;
    }
}