.management-main-div {
  margin-top: 5%;
}

.mang-ISExapndCollapsToggleLbl {
  margin-left: 6%;
}

.manage-row {
  padding: 2px 2px;
}

.div-inside-profit {
  padding: 12px 3px;
  border: 0.5px solid #e9d9d9;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.management-accordion {
  width: 60%;
  margin-left: 20%;
}

.manage-input {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 0;
  margin-right: 50px;
  width: 10em;
}

.eq-manage-input {
  width: 75%;
  height: 75%;
  border: 1px solid rgb(158, 154, 154);
  border-radius: 5px;
}

.management-acc-1 {
  width: 70%;
  margin-left: 14%;
}

.management-year-col {
  text-align: left;
}

.management-list-col {
  padding: 10px 00px;
}

a#pr_id_56_header_0,
a#pr_id_56_header_1,
a#pr_id_56_header_2,
a#pr_id_56_header_3,
a#pr_id_56_header_4,
a#pr_id_56_header_5 {
  background: #dbe2ef;
  border-color: #dee2e6;
  color: black;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0.1px solid #858687;
}

.Management-company-name {
  margin-left: -7%;
}

/* ///////////////// MGMT NEW //////////////////// */

.MSFYStyle {
  position: fixed;
  z-index: 1;
  width: 85.8%;
  margin-left: 13.3%;
  background-color: #dfdfdf;
  margin-top: 75px;
}

.MSFYStyle-sidebar-close {
  position: fixed;
  width: 95.7%;
  margin-left: 64px;
  margin-top: 75px;
  z-index: 1;
  background-color: #dfdfdf;
}

.MgmtExapndCollapsToggleLbl {
  text-align: end;
  margin-bottom: 0;
  margin-top: -40px;
  margin-left: 7px;
}

.MS_Main_Div {
  margin-top: 6.2%;
}

.mgmtFields {
  line-height: 42px;
  margin-left: -54px;
}

.mgmtLblRowMainsideDiv {
  width: 229%;
  margin-left: 81px;

}

.mgmtLineLbl {
  margin-left: -21px;

  font-size: 15px;
}

.mgmtTxtAreaDiv {
  margin-top: 5px;
  margin-left: 19px;
  margin-bottom: -10px;
  font-size: 14px;
}


@media screen and (max-width: 1536px) {
  .MSFYStyle {
    border-radius: 0;
    position: fixed;
    width: 81.5% !important;
    margin-left: 17.7% !important;
    z-index: 1;
    margin-top: 74px !important;
    background-color: #dfdfdf;
  }

  .MSFYStyle-sidebar-close {
    position: fixed;
    width: 95.4%;
    margin-left: 54px !important;
    z-index: 1;
    background-color: #dfdfdf;
    margin-top: 73px !important;
  }

  button.p-button.p-component.pdf_view_management {
    margin-left: 83.5% !important;
    margin-top: 24px;
  }

  .mgmtYearAmountBox>input {
    height: 36px;
    column-width: 100px;
    padding-right: 20px;
    border-radius: 7px;
    margin-right: 50px;
    width: 8.5em !important;
    text-align: right;
  }

  .management_lineDetails_tempCol2 {
    /* width: 14% !important; */
    /* margin-left: -0.5%; */
  }

  /* #MgmtStmtAddNewLineIcon {
    margin-left: 159px !important;
  }


  @-moz-document url-prefix() {
    #MgmtStmtAddNewLineIcon {
      margin-left: 170px !important;
    }
  } */

  .MSSideBarOpen {
    margin-left: 16% !important;
    margin-top: 72px !important;
  }

  .MSSideBarClose {
    margin-top: 59px !important;
  }

}

@media screen and (max-width: 969px) {
  .mgmtLineLbl {
    width: 125%;
  }
}

.mgmtIsInsideHeaderWithoutColValLbl {
  margin-left: -18px;

  font-size: 15px;
}

.mgmtCommentsPlaceholder {
  margin-left: -41px;
}

.mgmtTxtArea {
  border-radius: 10px;
  line-height: 21px;
}

.mgmtLineSumLbl {

  font-size: 14px;
  line-height: 1.2;
  font-weight: 550;
  margin-left: -20px;
}

.mgmtYearAmountBox>input {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 7px;
  margin-right: 50px;
  width: 11.5em;
  text-align: right;
}

.mgmtAmountBox>input {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 7px;
  margin-right: 50px;
  width: 10em;
  text-align: right;
}

.mgmtColWiseAmountBoxDisabled>input {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 7px;
  margin-right: 50px;
  width: 8.5em;
  font-weight: bold;
  background-color: #c7c7c79c;
  text-align: right;
}

.mgmtAmountBoxDisabled>input {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 7px;
  margin-right: 50px;
  width: 10em;
  font-weight: bold;
  background-color: #c7c7c79c;
  text-align: right;
}

.mgmtInsideTxtHeaderColumns {
  height: 40px;
  color: black;
  font-weight: 650;
  font-size: 12.5px;
  text-align: center;
  /* opacity: 0.6 !important */
  /* background: #d1d0d0; */
}

.mgmtAddNewLineITag {
  margin-left: -22px;
  color: green;
  font-weight: 700;
  font-style: normal;
  width: fit-content;
  border-radius: 11px;
  margin-bottom: -6px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: -10px;
  margin-right: 58%;
  display: inline-flex;
}

textarea#mgmtCustTxtAreaDiv {
  line-height: 21px;
  height: 36px;
  width: 75%;
  margin-bottom: 17px;
  font-size: 14px;
  border: solid 1px #ccc;
  border-radius: 10px;
  height: 47px;
  overflow: hidden;
  margin-left: 16px;

}

.MgmtSaveBtnCenter {
  margin-top: 2%;
  margin-bottom: 3.3%;
}

.MgmtSaveBtn {
  width: 185px !important;
  height: 50px !important;
  font-size: 16px !important;
}

#mgmtCustomRowDeleteOpenSideBar {
  color: #af3f3f;
  font-size: 17px;
  cursor: pointer;
  position: absolute;
  margin-top: 15px;
  margin-left: 11px;

}

#mgmtCustomRowDeleteCloseSideBar {
  font-size: 17px;
  margin-left: -17%;
  color: #af3f3f;
  cursor: pointer;
}

.mgmtHeaderMainDiv {
  line-height: 45px;
}

#MgmtStmtAddNewMatrixLineIcon {
  color: #104ca5bf;
  margin-left: 200px;
  top: -35px;
  position: relative;
  cursor: pointer;
  /* font-size: small; */
  display: flow;
  font-size: 17px;
}

#MgmtStmtAddNewLineIcon {
  color: #104ca5bf;
  margin-left: 170px;
  top: -30px;
  position: relative;
  cursor: pointer;
  /* font-size: small; */
  display: flow;
  font-size: 17px;
}

#MgmtStmtDeleteIcon2 {
  color: #af3f3f;
  margin-left: 170px;
  top: -30px;
  position: relative;
  cursor: pointer;
  /* font-size: small; */
  display: flow;
  font-size: 17px;
}

.Page_Title_manage_div {
  margin-top: 1%;
  margin-bottom: 13px;

}

.Page_Title_T1_Management {
  margin-left: 5px;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  color: #3f72af !important;
}

.Page_Title_management_Year {
  border: solid 1px transparent;

  margin-left: 3px;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  text-align: left;
  color: black;
}

button.p-button.p-component.pdf_view_management {
  margin-left: 86%;
  margin-top: 24px;
}

#mgmtDeleteHeader {
  color: #3f72af;
  margin-top: -9px;
  cursor: pointer;
  z-index: 0;
}

#mgmtDeleteHeader1 {
  color: #3f72af;
  margin-top: -9px;
  cursor: pointer;
  z-index: 0;
}

.mgmtStmntAddNewColumn {
  text-align: end;
  margin-bottom: -43px;
  font-size: 13px;
  margin-right: 19px;
  color: green;
  cursor: pointer;
}

.managementStatementSideBar {
  margin-top: 4.3rem;
  position: fixed;
}

.MSSideBarOpen {
  border-radius: 10px;
  margin-left: 12%;
  margin-right: 1%;
  margin-top: 51px;
  margin-bottom: 3.3%;
  min-height: 667px;
}

.MSSideBarClose {
  border-radius: 10px;
  margin-left: 2%;
  margin-right: 1%;
  margin-top: 42px;
  margin-bottom: 3.3%;
  min-height: 667px;
}


@-moz-document url-prefix() {
  .notesStringBox {
    line-height: 1.2;
    margin-left: 100px !important;
    height: 21px !important;
    width: 200px !important;
  }

  .Management_location_descrp {
    margin-top: 5px;
  }

  .mgmtTxtArea {
    margin-bottom: 20px;
  }
}

.mgmtCustTxtAreaDiv {
  z-index: 1;
  position: absolute;
  margin-left: 14px;
}

.matrixInputNumber>input {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 7px;
  margin-right: 50px;
  width: 11.5em;
  text-align: right;
  margin-bottom: 10px;
}

.matrixDisabledInputNumber>input {
  background: #d4d4d475;
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 7px;
  margin-right: 50px;
  width: 11.5em;
  text-align: right;
  margin-bottom: 10px;
}

.matrixHeaderCol1Lbl {
  margin-bottom: 20px;
  font-size: 15px;
  width: 100%;
  font-weight: bold;
}

.matrixHeaderLbl {
  margin-bottom: 20px;
  font-size: 15px;
  text-align: center;
  width: 182px;
  font-weight: bold;
}

/* #MgmtStmtDeleteIcon {
  font-size: 17px;
  color: #af3f3f;
  cursor: pointer;
  position: relative;
  display: flow;
} */

#MgmtStmtDeleteIcon {
  /* color: #104ca5bf; */
  color: #af3f3f;
  margin-left: 200px;
  top: -35px;
  position: relative;
  cursor: pointer;
  /* font-size: small; */
  display: flow;
  font-size: 17px;
}

#MgmtStmtAddNewLineIcon1 {
  margin-left: -34px;
  color: #104ca5bf;
  font-size: 17px;
  cursor: pointer;
  position: absolute;
  margin-top: 15px;
  margin-left: 11px;
}

.fromToDateHeader {
  width: 195px;
  height: 37px;
  font-size: 14px;
}

#InputNumber1 {
  height: 36px;
  margin-left: -4px;
}

#InputNumber1>input {
  border-radius: 7px;
}


.managementStmtNonDynamicColumnsLine {
  margin-left: 17px;
  margin-bottom: 15px;
}

.Management_EgetKapital {
  margin-left: 2%;
}