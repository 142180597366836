.dashboard-main-div {
    margin-top: 5%;
}

/* .dashboard_sidebar_div {
    margin-top: 70px;
    position: fixed;
} */

.toast-top {
    margin-top: 5%;
}


.myDashboardGridSideBarOpen {
    border-radius: 10px;
    margin-left: 269px;
    margin-right: 1%;
    margin-top: 90px;
    min-height: 657px;
    margin-bottom: 3.3%;
}

@media screen and (max-width: 1673px) {
    .myDashboardGridSideBarOpen {
        margin-left: 22%;
    }
}

@media screen and (max-width: 1536px) {
    .myDashboardGridSideBarOpen {
        margin-left: 18%;
    }
}

@media screen and (max-width: 1143px) {
    .myDashboardGridSideBarOpen {
        margin-left: 32%;
    }
}

@media screen and (max-width: 780px) {
    .myDashboardGridSideBarOpen {
        margin-left: 43%;
    }
}

@media screen and (max-width: 580px) {
    .myDashboardGridSideBarOpen {
        margin-left: 55%;
    }
}

input.p-inputtext.p-component.dashboard-search-bar {
    width: 300px;
}

.myDashboardGridSideBarClose {
    background-color: #f1f1f1;
    border-radius: 10px;
    margin-left: 4%;
    margin-right: 2%;
    /* margin-top: 90px; */
    margin-bottom: 3.3%;
}

@media screen and (max-width: 1309px) {
    .myDashboardGridSideBarClose {
        margin-left: 7%;
        margin-right: 1%;
    }
}

@media screen and (max-width: 751px) {
    .myDashboardGridSideBarClose {
        margin-left: 12%;
    }
}

@media screen and (max-width: 751px) {
    .myDashboardGridSideBarClose {
        margin-left: 12%;
    }

    input.p-inputtext.p-component.dashboard-search-bar {
        width: 100%;
    }
}


.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    border-width: 0px 0px 7px -6px;
}

.p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #DBE2EF;
    transition: box-shadow 0.2s;
}


.whole-delete {
    border: 1px solid red;
    background-color: red;
    color: white;
    border-radius: 5%;
    cursor: pointer;
}

button.p-button.p-component.delete-round-trash.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
    background: tras;
    background: transparent;
    color: red;
    border-radius: 50%;
    border-color: red;
}

th.p-sortable-disabled.p-selection-column.dashboard-checkbox {
    width: 57px;
}

.p-button-warning.mr-2 {
    border: 1px solid blue;
    background-color: transparent;
    border-radius: 50px;
    color: blue;
}

.p-button-warning.mr-2:hover {
    border: 1px solid blue;
    background-color: transparent;
    border-radius: 50px;
    color: blue;
}

.p-button.p-button-warning:enabled:hover,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button:enabled:hover,
.p-buttonset.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button:enabled:hover,
.p-splitbutton.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning:enabled:hover,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):hover {
    background: #8383ec;
    color: white;
    border-color: #8383ec;
}

th.p-sortable-disabled.dashboard-pdf {
    width: 5%;
}

.p-datatable-header {
    margin-top: -2%;
}

button.p-button.p-component.share-button.p-button-icon-only {
    background: transparent;
    color: green;
    border-color: green;
    border-radius: 50px;
}

.p-datatable .p-datatable-header {
    display: none;
}

button.p-button.p-component.share-button.p-button-icon-only:hover {
    background: green;
    color: white;
}

.emai-id-overlap {
    width: 134%;
    border-radius: 7px;
}

.report_share_confirm_email-textbox {
    width: 100%;
    border-radius: 7px;
}

.dashboard-form-control {
    width: 135%;
    border-radius: 5px;
}

.mail-give-access-dropdown {
    width: 500px;
    margin-left: -20%;
}

.share-annual-text-area {
    height: 60%;
    width: 96%;
    border-radius: 7px;
}

.share-dialog {
    width: 35rem;
    text-align: center;
    max-height: 40rem;
}

.share-split {
    height: 64px;
}

.share-split-row {
    height: 150px;
    width: 100%;
}

.share-split-col {
    border-right: 1px solid green;
}

thead.p-datatable-thead {
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
}

.p-datatable .p-datatable-tbody>tr {
    background: #ffffff;
    color: #495057;
    transition: box-shadow 0.2s;
    font-size: 14px;
}


.p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #6c757d;
    width: 1.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-column-filter-menu {
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.mb-4 {
    margin-bottom: 0.5rem !important;
}

th.dashboard-org-no {
    width: 1px;
}



th.dashboard-share-within-company {
    width: 163px;
}

th.dashboard-created-on {
    width: 8%;
}

.dashbaord-companyName {
    cursor: pointer;
}

input.p-inputtext.p-component.dashboard-email-input {
    width: 96%;
}

label.email-input-label {
    margin-top: 1px;
}


button.p-button.p-component.dashboard-receipt-bill.p-button-icon-only {
    border: none;
    border-radius: 50px;
}

.people-access {
    text-align: left;
    margin-left: 5%;
    font-weight: 600;
}

.mail-and-access {
    display: flex;
    width: 89%;
    margin-left: 5%;
}

.dashboard-share-access {
    width: 100%;
    border-top-color: transparent;
    border-left: transparent;
    border-right: transparent;
    border-radius: 0px;
    text-align: left;
}

.email-add-div {
    width: 75%;
    text-align: left;
    vertical-align: middle;
}

p.email-address {
    margin-top: 12px;
}

.mail-access-dropdown {
    text-align: right;
    width: 25%;
}

.give-mail-and-access {
    display: flex;
    width: 95%;
}

.email-enter-div {
    width: 70%;
}

.mail-give-access-dropdown-div {
    width: 30%;
}

.p-dropdown.p-component.p-inputwrapper.mail-give-access-dropdown {
    width: 67%;
    border-radius: 7px;
    text-align: center;
}

.dashboard-financial-year {
    width: 10%;
}


.dashboard-company-name {
    width: 10%;
}

th.dashboard-financial-year {
    width: 10%;
}

.dashboard-share-within-company {
    width: 15em;
}

th.dashboard-org-no {
    width: 10%;
}

.addNewReportShareAccessBtn {
    margin-left: 81%;
}

.addNewReportShareAccessDiv {
    margin-top: -3%;
    margin-bottom: 3%;
}

.confirmation-content {
    margin-top: 28px;
}

.people-with-access {
    text-align: left;
    font-weight: 600;
    font-size: 1.1em;
    margin-bottom: 10px;
}

.share-overlap-row {
    width: 100%;
}

.email-id-warning {
    text-align: left;
    color: red;
}

.share-message {
    text-align: left;
    font-weight: 600;
    margin-bottom: 2%;
}

.link-email-input-box {
    width: 200px;
}

.link-access-dropdown {
    text-align: end;
}

#new-report-dashboard {
    margin-left: 2%;
}


.dashboard-share-btn {
    margin-top: 3%;
}

.dashboard-linksharing-card {
    margin-left: 2%;
}

.reportShareDetails {
    margin-bottom: 10px;
}

.dash-inside-organization {
    text-align: center;
    margin: 10%;
}

.inside-heading2 {
    margin-left: 35%;
}

.inside-heading1 {
    margin-left: 30%;
}

.inside-col {
    text-align: center;
}

.common-share-dash {
    margin-bottom: 3%;
    text-align: center;
}

.report-sharing-card>.p-card-body>.p-card-title {
    font-size: 1.2rem;
}

.dashboard-linksharing-card>.p-card-body>.p-card-title {
    font-size: 1.2rem;
}

@media screen and (max-width: 1536px) {
    .myDashboardGridSideBarOpen {
        margin-left: 18%;
    }
}