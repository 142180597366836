 .SRU_main-container-sidebarOpenClass {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     display: flex;
     position: absolute;
     float: right;
     overflow: hidden;
     height: 100vh;
     margin-top: 6px;
 }

 .SRU_main-container-sidebarCloseClass {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     display: flex;
     position: absolute;
     float: right;
     overflow: hidden;
     height: 100vh;
     /* margin-top: 4%; */
 }

 .SRU_sidebar {
     background: rgb(248, 247, 247);
     color: white;
     height: 166vh;
     overflow: hidden;
 }

 .SRU_sidebarExtent {
     background: rgb(248, 247, 247);
     color: white;
     height: 123vw;
     overflow-y: auto;
     min-height: 40vw;
     max-height: 130vw;
 }

 .SRU_top_section {
     display: flex;
     align-items: center;
     justify-content: end;
     height: 3%;
 }

 .SRU_bars {
     width: 30px;
     margin-top: 11px;
 }

 .SRU_routes {
     margin-top: 0px;
     display: flex;
     flex-direction: column;
     gap: 0px;
 }

 p.SRU_subtext-sru-report {
     color: black;
     margin: 0px;
     position: relative;
     margin-right: 85px;
     font-size: 18px;
     font-weight: 400;
     margin-top: 16px;
 }

 .SRU_sideBarNotAccessableToast {
     top: 83%;
     right: 20px;
 }

 .new-SRU-report-create-button {
     border: none;
     border-radius: 5px;
     background-color: rgb(177, 84, 34);
     color: white;
     width: 170px;
     height: 40px;

 }

 .new-SRU-report-create-button:hover {
     background-color: rgb(134, 52, 8);
     color: white;
 }

 .SRU_tickIcon {
     width: 22px;
     position: absolute;
     margin-left: -10px;
 }

 .SRU-sidebarActive {
     display: flex;
     color: rgb(255 255 255);
     gap: 10px;
     padding: 5px 10px;
     border-right: 4px solid transparent;
     transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
     text-decoration: none;
     background: rgb(177, 84, 34);
 }