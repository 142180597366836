.reviewAndSendPaymentSuccess {
    padding-left: 15%;
    padding-right: 7%;
    top: 21px;
    position: relative;
    margin-bottom: -65px;
}

.reviewAndSend-div {
    background: var(--surface-card);
    border-radius: 10px;
    margin-left: 15%;
    margin-right: 7%;
    margin-top: 73px;
    margin-bottom: 3.3%;
    min-height: 672px;
    top: 30px;
    position: relative;
}

.review-edit {
    margin: 0px 75px;
    padding: 10px;

}

.review-submit {
    margin: 50px;
    padding-bottom: 50px;
}

.sru-info-div {
text-align:left;
margin: 2% 10%;
padding: 2%;
background-color: rgb(207, 205, 205);
}