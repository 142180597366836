:root {
  --ps-blue: #1b86fa;
  --ps-blue-hover: #4a9dff;
  --ps-orange: #ff5f00;
  --ps-orange-hover: #ff8233;
}

@font-face {
  font-family: Segoe-UI;
  src: url(./Assests/Segoe\ UI.ttf);
}


#root {
  font-family: Segoe-UI;
  background-color: #fff8f896;
}

body {
  font-family: Segoe-UI;
}

.statusAlterBar {
  position: fixed;
  width: 100%;
  height: 25px;
  z-index: 9;
}

.app {
  height: 1000px;
}

.app2 {
  height: 1000px;
  padding-top: 39px;
}

.CookieConsent {
  background-color: rgb(248, 247, 247) !important;
  color: black !important;
  z-index: 9999 !important;
  border-top: 1px solid darkgray;
}

button#rcc-decline-button {
  display: none;
}

.react-joyride__tooltip>button {
  display: none !important;
}