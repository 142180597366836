.payment-accordion {
    width: 100%;
}

th.payment-quantity > div {
    margin-left: 8%;
}

.payment-extra-text {
margin-top: 2%;
margin-left: 2%;
font-size: 18px;
font-weight: 600;
}

#toggle-open-payment {
    margin-top: 14px;
}

input.p-inputtext.p-component.payment-discount-email {
    width: 50%;
}

input.p-inputtext.p-component.payment-discount-code {
    width: 50%;
}

textarea.p-inputtextarea.p-inputtext.p-component.Regulations-acc {
    width: 70%;
    margin-left: 14%;
}

input.p-inputtext.p-component.payment-private-username {
    width: 25%;
    margin-right: 1%;
}

input.p-inputtext.p-component.payment-private-email {
    width: 30%;
}

input.p-inputtext.p-component.payment-accounting-username {
    width: 30%;
}

input.p-inputtext.p-component.payment-accounting-email {
    width: 30%;
}

.radiobutton-payson {
    margin-top: 1px;
}

#pricingTableSuggestion {
    margin: 0%;
}

.default-pricing-div {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 1px solid rgb(217, 212, 212);
    padding: 1% 5%;
    margin: 2% 5%;
    text-align: center;
}

.paymentSideBar {
    margin-top: 70px;
    position: fixed;
}

.PaymentExapndCollapsToggleLbl {
    text-align: end;
    width: 84.7% !important;
    margin-left: 12px !important;
    margin-top: -1px;
    display: inline-block;
    position: fixed;
    z-index: 9;
    height: 9%;
    background-color: #dfdfdf;
}

.Payment_Main_Div {
    margin-top: 4.9%;
}

.Payment_PageTitle {
    margin-bottom: -55px;
    margin-top: 1%;
}

.Page_Title_payment {
    font-size: 18px;
    font-weight: 800;
    text-align: left;
    color: #3f72af !important;

    margin-top: 9px;
}

.payment-div {
    border-radius: 10px;
    margin-left: 13% !important;
    margin-right: 1%;
    margin-top: 76px;
    margin-bottom: 3.3%;
    min-height: 672px;
}

.payment-div-sidebar {
    border-radius: 10px;
    margin-left: 13% !important;
    margin-right: 0% !important;
    margin-top: 4%;
    margin-bottom: 3.3%;
    min-height: 672px;
}

.payment-accordion {
    width: 100%;
}

.payment_submit_btn {
    margin-left: 0%;
}

.Page_Title_Paymemt_Year {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    height: 36px;
    border: solid 1px transparent;
    margin-left: 5px;
    width: 250px;
    margin-right: 57px;

    color: rgb(43, 42, 42);
}

.BSFYStylepayment {
    margin-bottom: 3.8%;
}

.payment_total_price {

    text-align: left;
    margin-left: 27em;
}


.companyInformationTextBoxTitle {

    color: rgb(70, 69, 69);
}

.payment_payson {
    border: 2px solid #ada6a6;
    border-radius: 9px;
}

.Page_Title_T1_payment_name {
    margin-left: 7px;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    color: #3f72af !important;
}

button.p-button.p-component.pdf_view_payment {
    margin-bottom: 1%;
}

.totalSums {
    text-align: right;
    width: 50%;
    margin-left: -115px;
}

.payment-restriction {


    padding: 160px 0px;
    font-size: 22px;
}

.payment-confirm-div {
    margin: 10% 30%;


    box-shadow: 5px 10px 8px #888888;
}

.payment-confirm-div h3 {
    margin-top: 2%;
}

.payment-approval-tick {
    font-size: 5rem;
}

.payment-confirm-next-div {
    padding: 5%;
}

.mand-bill-info {

    color: #0a84e8;
}

.already-paid-div {
    margin: 12% 25%;
    font-size: 20px;
    box-shadow: 10px 9px 15px 1px #888888;
    padding: 5%;
}


.payment-info-div {
    padding: 0% 3% 0% 4%;
}

.PC-invoice-mail {
    text-decoration: underline;
}


@media screen and (max-width: 1770px) {
    .payment_total_price {
        margin-left: 10em;
    }
}

@media screen and (max-width: 1536px) {
    .payment-div {
        margin-left: 5%;
        margin-right: 7%;
        margin-top: 1%;
    }

    .Payment_Main_Div {
        margin-left: 7% !important;
    }

    .payment-div-sidebar {
        margin-left: 2% !important;
        margin-right: 9% !important;
        margin-top: 1%;
    }
}



@media screen and (max-width: 1200px) {
    .payment_total_price {
        margin-left: 05em;
    }
}

@media screen and (max-width: 958px) {
    .payment_total_price {
        margin-left: 01em;
    }
}

@media screen and (max-width: 912px) {
    .totalSums {
        text-align: right;
        width: 58%;
        margin-left: 7px;
    }

    .payment-div {
        margin-left: 5%;
        margin-right: 8%;
        margin-top: 4%;
    }
}

@media screen and (max-width: 800px) {
    .totalSums {
        text-align: right;
        width: 82%;
        margin-left: 1px;
    }

    .payment-div {
        margin-left: 5%;
        margin-right: 4%;
        margin-top: 4%;
    }
}

@media screen and (max-width: 700px) {
    .payment-div {
        margin-left: 7%;
        margin-right: 4%;
        margin-top: 7%;
    }
}

@media screen and (max-width: 670px) {
    .payment_iframeDiv {
        width: 581px;
        margin-left: -10px;
    }

    .payment_information_fill-div {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        font-size: 16px;
        width: 100%;
    }
}


@media screen and (max-width: 610px) {
    .payment_iframeDiv {
        width: 529px;
        margin-left: -11px;
        height: 900px;
    }

    .totalSums {
        text-align: right;
        width: 96%;
        margin-left: -25px;
    }

    .payment_information_fill-div {
        font-size: 14px;
    }
}

@media screen and (max-width: 590px) {
    .payment_iframeDiv {
        width: 509px;
        margin-left: -11px;
        height: 900px;
    }

    .payment_total_price {
        margin-left: -1em;
    }


}

@media screen and (max-width: 550px) {
    .payment_iframeDiv {
        padding: 0px 7px 0px 5px;
        width: 467px;
    }

    .payment_total_price {
        margin-left: -7em;
    }
}

@media screen and (max-width: 500px) {
    .payment_iframeDiv {
        width: 444px;
    }

    .pricingTable {
        margin: 4% 1%;
    }

    .payment_information_fill-div {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        font-size: 10px;
    }

    .payment-bill-info-name {
        font-size: 10px;
    }

    .payment-bill-info-email {
        font-size: 10px;
    }
}

@media screen and (max-width: 460px) {
    .payment_iframeDiv {
        width: 400px;
    }
}

@media screen and (max-width: 430px) {
    .payment-div {
        margin-top: 15%;
    }

    .payment_iframeDiv {
        width: 380px;
    }

    .payment_total_price {
        margin-left: -11em;
    }
}

@media screen and (max-width: 400px) {
    .payment-div {
        margin-top: 18%;
        margin-left: 11%;
    }

    .payment_iframeDiv {
        width: 340px;
    }

    .payment_totalCalc_Div {
        padding: 0px 1px 0px 0px;
        font-size: 14px;
    }
}

@media screen and (max-width: 390px) {
    .payment_iframeDiv {
        width: 335px;
    }
}

@media screen and (max-width: 375px) {
    .payment_iframeDiv {
        padding: 0px 10px 0px 22px;
        width: 332px;
        height: 930px;
    }

    .payment_iframeDiv {
        width: 308px;
    }
}

@media screen and (max-width: 360px) {
    .payment_iframeDiv {
        width: 303px;
    }
}

@media screen and (max-width: 320px) {
    .payment-div {
        margin-top: 18%;
        margin-left: 11%;
        margin-right: -10%;
    }

    .payment_iframeDiv {
        padding: 0px 45px 0px 22px;
        width: 320px;
    }

    .payment_totalCalc_Div {
        padding: 0px -3px 0px 6px;
        font-size: 14px;
    }
}

@media screen and (max-width: 315px) {
    .payment_iframeDiv {
        padding: 0px 1px 0px 3px;
        width: 274px;
    }

    .payment_totalCalc_Div {
        width: 117%;
        font-size: 14px;
    }
}

@media screen and (max-width: 300px) {
    .payment_totalCalc_Div {
        font-size: 12px;
    }
}

.packageCarouselCard {
    padding: 0% 8%;
}