.status-alert-div {
  position: fixed;
  background-color: red;
  color: white;
  font-weight: 600;
  width: 100%;
}

.statusAlterP-tag {
  font-size: 15px;
}

@media screen and (max-width: 1920px) {
  .status-alert-div {
    padding: 0px 0px;
    height: 40px;
  }

  .statusAlterP-tag {
    font-size: 16px;
    margin-top: 8px;
  }
}

@media screen and (max-width: 1439px) {
  .status-alert-div {
    z-index: 0;
  }
}

@media screen and (max-width: 1024px) {
  .status-alert-div {
    padding: 0px 0px;
    height: 40px;
  }

  .statusAlterP-tag {
    font-size: 16px;
    margin-top: 8px;
  }
}

@media screen and (max-width: 800px) and (max-width: 700px) {
  .status-alert-div {
    padding: 1px 0px;
    height: 25px;
  }
}

@media screen and (max-width: 754px) {
  .status-alert-div {
    padding: 1px 0px;
    height: 39px;
  }

  .statusAlterP-tag {
    font-size: 12px;
  }
}


@media screen and (max-width: 670px) {
  .status-alert-div {
    padding: 1px 0px;
    height: 39px;
  }
}

@media screen and (max-width: 610px) {
  .status-alert-div {
    padding: 1px 0px;
    height: 39px;
  }
}

@media screen and (max-width: 590px) and (max-width: 540px) {
  .status-alert-div {
    padding: 0px 0px;
    height: 40px;
  }

  .statusAlterP-tag {
    font-size: 13px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .status-alert-div {
    padding: 1px 0px;
    height: 48px;
  }
}

@media screen and (max-width: 430px) {
  .status-alert-div {
    padding: 0px 0px;
    height: 40px;
  }

  .statusAlterP-tag {
    font-size: 12px;
    margin-top: 5px;
  }
}


@media screen and (max-width: 390px) and (max-width: 340px) {
  .status-alert-div {
    padding: 3px 0px;
    height: 45px;
  }

  /* .statusAlterP-tag{
    font-size: 12px;
  } */
}


@media screen and (max-width: 320px) {
  .status-alert-div {
    padding: 3px 0px;
    height: 45px;
  }

  .statusAlterP-tag {
    font-size: 12px;
  }
}