.digitalSignature-div {
    border-radius: 10px;
    padding: 5em 1em 0em 18em;
    min-height: 710px;
    margin-left: -2%;
    margin-right: 0%;
}

.digitalSignature-div-sidebar {
    border-radius: 10px;
    margin-left: 3.5%;
    margin-right: 1%;
    margin-top: 4%;
    min-height: 710px;
}

button.p-button.p-component.pdf_view_sig {
    margin-top: 2%;
    text-align: end;
    margin-bottom: 1%;
    margin-right: 5%;
}

.dseBtn {
    display: flex;
    align-items: center;
}

.dseBtn::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('../../Assests/BankID_logo_white.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 8px;
}

.dseSuccessTag {
    display: flex;
    align-items: center;
}

.dseSuccessTag::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('../../Assests/BankID_logo_white.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 8px;
}

.digitalSignPdfDiv {
    text-align: end;
    margin-bottom: 13px;
    background-color: #dfdfdf;
}

.digitalSignCheckRespHeading {
    font-weight: 900;
    font-size: 22px;
    margin-bottom: 10px;
}

.digitalSignCheckRespDetails {
    color: #404F5E;
    font-size: 18px;
    margin: 0;
}

.digitalSignCheckRespIcon {
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

#digitalSignCheckRespIcon {
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

.digitalSignCheckRespCard {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    margin: 0 auto;
}

.digitalSignCheckRespCardDiv {
    border-radius: 200px;
    height: 200px;
    width: 200px;
    margin: 0 auto;
}

.bankIdQRScanner {
    height: 13em;
}

.digitalSignDialogTitle {
    font-size: 30px;
    height: 3em;
    display: block;
}

.digitalSignDialogSubTitle {
    font-size: 18px;
}

.digitalSignDialogScannerDiv {
    border: 1px solid lightslategray;
    padding: 20px 80px 20px 80px;
}

.digitalSignQrCodeProgress {
    margin-top: 48px;
}

.digitalSignatureQRFailSSN {
    padding: 10px;
}

.ds-steps {
    margin-left: 5%;
    text-align: left;
    font-size: 13px;
}

.ds-steps-title {
    text-align: left;
    font-size: 15px;
}

.Page_Title_ds {
    margin-top: 1%;
    margin-bottom: 6px;
}

.Page_Title_T1_ds_name {
    margin-left: 3px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    color: #3f72af !important;
}

.Page_Title_ds_Year {
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    height: 36px;
    border: solid 1px transparent;
    text-align: left;
    margin-left: 3px;
}

.digitalSigantureBankID_Dialogbox {
    border-radius: 10px;
    width: 20%;
}

@media screen and (max-width: 1536px) {
    .digitalSigantureBankID_Dialogbox {
        border-radius: 10px;
        width: 26%;
    }

}