.BSSubHeadingStyle {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 550;
}

.BSSideBarOpen {
  border-radius: 10px;
  margin-left: 13.1%;
  margin-right: 0%;
  margin-top: 74px;
  margin-bottom: 3.3%;
  min-height: 667px;
}

.BSSideBarClose {
  border-radius: 10px;
  margin-left: 3%;
  margin-right: 0%;
  margin-top: 66px;
  margin-bottom: 3.3%;
  min-height: 667px;
}

.BSFieldsStyle {

  font-size: 15px;
  line-height: 1.2;
  margin-left: -28px;
}

.BSFYStyle {
  position: fixed;
  z-index: 1;
  width: 85.7%;
  margin-left: 13.6%;
  background-color: #dfdfdf;
  margin-top: 75px;
}

.BSFYStyle-sidebar-close {
  position: fixed;
  width: 95.78%;
  margin-left: 67px;
  margin-top: 75px;
  z-index: 1;
  background-color: #dfdfdf;
}

.BSExapndCollapsToggleLbl {
  text-align: end;
  margin-left: -30px;
  margin-top: 5px;
  /* position: fixed; */
  z-index: 9999;
}

.Page_Title_balanceSheet {
  margin-top: 1%;
  margin-bottom: 19px;

}

.Page_Title_T1_balanceSheet {
  margin-left: 5px;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  color: #3f72af !important;
}

.BS_Main_Div {
  margin-top: 4%;
}

@media screen and (max-width: 1111px) {
  .BSExapndCollapsToggleLbl {
    margin-bottom: -18px;
    margin-top: 26px;
  }
}

@media screen and (max-width: 1386px) {
  .BSExapndCollapsToggleLbl {
    margin-bottom: -18px;
    margin-top: 26px;
  }
}

.BSFYStyle-BS {
  margin-bottom: 0%;
  margin-top: 75px;
  margin-left: 15.6%;
  width: 80.8%;
  position: fixed;
  background-color: #dfdfdf;
  z-index: 9;
  height: 8%;
}

.BSFYStyle .BSFYStyle-scroll {
  background-color: red;
}

.BSparent {
  display: flex;
  margin-top: 10px;
}

.BSAmountBoxCol {
  display: flex;
}

.BSFromFY {
  height: 36px;
  border: solid 1px transparent;
  margin-left: -8px;
  width: 160px;
  margin-right: 57px;

}

.BSToFY {
  height: 36px;
  border: solid 1px transparent;
  margin-top: -16px;
  margin-left: -22px;
  width: 160px;
  margin-right: 57px;

}

.BSFromFY>input,
.BSToFY>input {
  border: 0px solid #ced4da !important;
  font-weight: 500;
  pointer-events: none;
}

.BSFromFY :focus {
  box-shadow: 0 0 0 0rem #a6d5fa !important;
}

.BSToFY :focus {
  box-shadow: 0 0 0 0rem #a6d5fa !important;
}

.BSTotalFieldsStyle {

  font-size: 15px;
  line-height: 1.2;
  font-weight: 550;
}

.BSAmountBoxDisabled {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
}

.BSAmountBoxDisabled>input {
  /* background-color: #ffffff; */
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 0;
  margin-right: 30px;
  width: 10em;
  /* color: black; */
  font-weight: bold;
  background-color: #c7c7c79c;
  text-align: right;
}

.BSInputBox>input {
  height: 36px;
  column-width: 100px;
  padding-right: 20px;
  border-radius: 7px;
  margin-right: 50px;
  width: 10em;
  text-align: right;
}

.BSSaveBtnCenter {
  margin-top: 2%;
  margin-bottom: 3.3%;

}

.BSSaveBtn {
  width: 185px !important;
  height: 50px !important;
  font-size: 16px !important;

}

/* /////////////////////////////////////////////////// */

.BSFieldsStyle {

  font-size: 15px;
  line-height: 1.2;
}

.BSFields {
  line-height: 50px;
  margin-left: -76px;
}

.BScustomFieldText {
  line-height: 21px;
  height: 47px;
  width: 65%;
  margin-bottom: 17px;
  font-size: 14px;
  border: solid 1px #ccc;
  border-radius: 10px;
  margin-bottom: -14px;

}

.BSAddNewLbl {
  width: 20%;
  margin-left: 41px;
  color: green;
  font-weight: 700;
  font-style: normal;
  width: fit-content;
  border-radius: 11px;
  margin-bottom: -6px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0px;
}

.BSTotalFieldsStyle {

  font-size: 15px;
  line-height: 1.2;
  font-weight: 550;
  margin-top: 25px;
}

.BSAmountBoxCol {
  display: flex;
}

@media screen and (max-width: 1652px) {
  .BSSideBarOpen {
    margin-left: 17%;
    margin-top: 79px;
  }
}

@media screen and (max-width: 1536px) {

  .BS_Main_Div {
    margin-top: 4.9%;
  }

  .BSFYStyle {
    position: fixed;
    z-index: 1;
    width: 81.4%;
    margin-left: 268px;
    background-color: #dfdfdf;
    margin-top: 75px;
  }

  .BSFYStyle-sidebar-close {
    position: fixed;
    width: 95.4%;
    margin-left: 56px;
    margin-top: 70px;
    z-index: 1;
    background-color: #dfdfdf;
  }

  .BSSideBarOpen {
    margin-left: 17%;
    margin-top: 79px;
  }

  .BSSideBarClose {
    margin-top: 64px;
  }

}

@media screen and (max-width: 1522px) {
  .BScustomFieldText {
    width: 64%;
  }
}

@media screen and (max-width: 1388px) {
  .BScustomFieldText {
    margin-left: 21%;
    margin-right: -3%;
  }

  .BSSideBarOpen {
    margin-left: 21%;
    margin-right: -5%;
  }
}

@media screen and (max-width: 1324px) {
  .BScustomFieldText {
    width: 56%;
  }

  .BSSideBarOpen {
    margin-left: 23%;
    margin-right: -12%;
  }
}

@media screen and (max-width: 1249px) {
  .BScustomFieldText {
    width: 56%;
  }

  .BSSideBarOpen {
    margin-right: -17%;
  }
}

@media screen and (max-width: 1190px) {
  .BScustomFieldText {
    margin-left: 25%;
    margin-right: -6%;
  }

  .BSSideBarOpen {
    margin-right: -22%;
  }

  .BSSideBarClose {
    margin-left: 7%;
    margin-right: -3%;
  }
}

@media screen and (max-width: 1116px) {
  .BSSideBarOpen {
    margin-left: 25%;
    margin-right: -27%;
  }

  .BSSideBarClose {
    margin-right: -9%;
  }
}

@media screen and (max-width: 1080px) {
  .BSSideBarOpen {
    margin-left: 25%;
    margin-right: -35%;
  }

  .BSSideBarClose {
    margin-right: -12%;
  }
}

@media screen and (max-width: 1028px) {
  .BSSideBarClose {
    margin-right: -18%;
  }
}

@media screen and (max-width: 998px) {
  .BSSideBarOpen {
    margin-left: 29%;
    margin-right: -50%;
  }

  .BSSideBarClose {
    margin-right: -25%;
  }
}

@media screen and (max-width: 910px) {
  .BSSideBarOpen {
    margin-left: 34%;
    margin-right: -64%;
  }

  .BSSideBarClose {
    margin-right: -36%;
  }
}

#BSCustomRowDelete {
  font-size: 17px;
  margin: 12px;
  margin-left: -44px;
  color: #af3f3f;
  cursor: pointer;
  margin-top: 16px;
}

@media screen and (max-width: 860px) {
  .BSSideBarOpen {
    margin-left: 37%;
    margin-right: -71%;
  }

  .BSSideBarClose {
    margin-right: -45%;
  }
}

@media screen and (max-width: 824px) {
  .BSSideBarOpen {
    margin-left: 37%;
    margin-right: -85%;
  }
}

@media screen and (max-width: 788px) {
  .BSSideBarClose {
    margin-right: -50%;
  }
}

.BSLoading {
  text-align: center;
  padding: 21em;
}

.bsDifferencesMainDiv {
  font-weight: 600;
}

#balanceSheetIsFirstLineSum {
  margin-top: 3px;
}

.BSFYMainDiv {
  display: grid;
}

#BSLblRowInsideDivId {
  display: grid !important;
  margin-top: -6px;
  line-height: 45px;
}

button.p-button.p-component.pdf_view_balancesheet {
  margin-top: 6%;
  margin-left: -115%;
  font-size: 14px;
  margin-top: 5px;
  /* z-index: -1; */
}

.first-container-bs-title {
  background: url(https://images.unsplash.com/photo-1488831861984-179da3647265?dpr=2&auto=format&fit=crop&w=1500&h=938&q=80&cs=tinysrgb&crop=) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.nav-container-bs-title {
  height: 58px;
  width: 100%;
  position: fixed;
  color: white;
  letter-spacing: 0.025em;
  transition: background-color 0.2s linear;

  &.solid-nav-bs-title {
    background-color: #aaa;
    transition: background-color 0.2s linear;
  }
}

.BSLblRowMainsideDiv>div {
  margin-left: -20px;
}

.BSLblRowMainsideDiv>div>div>input {
  /* width: 175%; */
  border: 1px solid #dadada;
  /* border-radius: 10px; */
  font-size: 15px;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
}



textarea.auto-resize-textarea {
  margin-left: 13em;
  outline: none;
  width: 350px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-height: 40px;
  /* height: auto; */
  line-height: 1.5;
  padding: 5px;
  border-radius: 10px;
  border-color: #cdcdcd;
}

.autoCompleteDropdownMenus {
  margin-left: 13em;
}

textarea.auto-resize-textarea-linelevel-4 {
  margin-left: 11em;
  outline: none;
  width: 350px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-height: 40px;
  /* height: auto; */
  line-height: 1.5;
  padding: 5px;
  border-radius: 10px;
  border-color: #cdcdcd;
}

.autoCompleteDropdownMenus-linelevel-4 {
  margin-left: 11em;
}

@media screen and (max-width: 1880px) {
  textarea.auto-resize-textarea {
    margin-left: 13em;
    outline: none;
    width: 350px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    min-height: 40px;
    /* height: auto; */
    line-height: 1.5;
    padding: 5px;
    border-radius: 10px;
    border-color: #cdcdcd;
  }

  .autoCompleteDropdownMenus {
    margin-top: 5px;
    min-height: 200px;
    overflow-y: auto;
    width: 360px;
    line-height: 2;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: 13em;
  }

  textarea.auto-resize-textarea-linelevel-4 {
    margin-left: 11em;
    outline: none;
    width: 350px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    min-height: 40px;
    /* height: auto; */
    line-height: 1.5;
    padding: 5px;
    border-radius: 10px;
    border-color: #cdcdcd;
  }

  .autoCompleteDropdownMenus-linelevel-4 {
    margin-top: 5px;
    min-height: 200px;
    overflow-y: auto;
    width: 360px;
    line-height: 2;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: 11em;
  }
}

@media screen and (max-width: 1536px) {
  textarea.auto-resize-textarea {
    margin-left: 12em;
    outline: none;
    width: 275px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    min-height: 40px;
    /* height: auto; */
    line-height: 1.5;
    padding: 5px;
    border-radius: 10px;
    border-color: #cdcdcd;
  }

  .autoCompleteDropdownMenus {
    margin-top: 5px;
    min-height: 200px;
    overflow-y: auto;
    width: 275px;
    line-height: 2;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: 12em;
  }

  textarea.auto-resize-textarea-linelevel-4 {
    margin-left: 9em;
    outline: none;
    width: 275px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    min-height: 40px;
    /* height: auto; */
    line-height: 1.5;
    padding: 5px;
    border-radius: 10px;
    border-color: #cdcdcd;
  }

  .autoCompleteDropdownMenus-linelevel-4 {
    margin-top: 5px;
    min-height: 200px;
    overflow-y: auto;
    width: 275px;
    line-height: 2;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: 9em;
  }
}