.import-sie-div {
  background-color: white;
  margin: 10px;
  text-align: end;
  margin-bottom: -65px;
  margin-top: 70px;
  width: 800px;

}

.import-sie-span {
  text-align: end;
  color: #fff;
}

.import-sie-span>a {
  align-items: end;
  text-align: end;
  text-decoration: none;

  font-size: 15px;
  border: 2px solid #2F4E88EE;
  background-color: #2F4E88EE;
  color: #fff;
  padding: 10px;
  border-radius: 5px;

}

.p-fileupload-buttonbar .p-button:not(.p-fileupload-choose) {
  display: none;
}

.p-fileupload-file-badge {
  display: none;
}

.import-sie-span>a:hover {
  align-items: end;
  text-align: end;
  text-decoration: none;

  font-size: 15px;
  border: 2px solid #2196F3;
  background-color: #2196F3;
  color: #fff;
  padding: 10px;
  border-radius: 5px;

}

.dragdrop {
  border: 4px solid black;
  text-align: center;
}


.createARLabel {
  margin-left: 45%;
  margin-top: 5%;
  margin-bottom: -7%;
  padding: 2% 10% 0% 0%;
  font-size: 21px;
  font-weight: bold;
  color: black;

}


#import_SIE {
  border-radius: 5px;
  border: none;
  color: #ffffff;
  text-align: center;
  padding: 15px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  width: 150px;
  height: 40px;
  background: #3F72AF;
  font-size: 1.1rem;

  position: relative;
}


.info-year-container {
  box-shadow: 5px 10px 8px #888888;
  border: 1px solid white;
  margin-top: 1%;
  width: 900px;
  margin-bottom: 6%;
  margin-left: 28%;

}

.card {
  background-color: white;
}

label {
  color: black;
  padding-left: 40px;
}

.info-col {
  background-color: white;
  margin: 18px;
  margin-left: 40px;
}

.info-org-no,
.info-org-postal {
  width: 300px;
}

.info-org-name,
.info-org-city {
  width: 370px;

}

p.info-warningLabel {
  margin-left: 10px;
  text-align: start;
  color: red;

  position: absolute;
}

.info-finance-container {
  background-color: rgb(222, 220, 220);
  box-shadow: 5px 10px 8px #888888;
  border: 1px solid white;
  width: 1300px;
}

.year-col-1 {
  padding-top: 10px;
}

#icon {
  align-items: left;
}

.year-div {
  text-align: center;
  align-items: center;
  width: 700px;
  margin-bottom: 31px;

}

.year-cal-label-container {
  width: 700px;
  margin-top: -17px;
}

.year-drop-label-main {
  text-align: center;
  margin-top: 4%;
}


.yaer-cal-label-head {
  text-align: left;
  font-size: 17px;
  margin-left: 30px;
  width: 600px;
  font-weight: bold;
}

.year-cal-label {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  font-size: 15px;

  margin-top: -5px;
  /* margin-left: 4%; */
}

.year-cal-box {
  display: flex;
  width: 700px;
  gap: 12.2em;
  /* margin-left: 2.2em; */

}

#starting-date,
#pre-starting-date {
  margin-left: 10px;
}

#ending-date,
#pre-ending-date {
  margin-left: 175px;
}

.pre-year-cal-label {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  font-size: 15px;

  margin-top: 12px;
  gap: 22.2em;
  margin-left: 2.2em;
}

.year-cal-label-2 {
  padding-top: 9px;
  text-align: end;
  margin-left: 318px;
}

.slider-div {
  width: 49%;
  margin-left: 5%;
  margin-right: 100px;
  margin-bottom: -13px;
}



input.p-inputtext.p-component.info-org-postal {
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0
}

input.p-inputtext.p-component.p-inputmask.info-org-no {
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0
}

input.p-inputtext.p-component.info-org-name {
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0
}

input.p-inputtext.p-component.info-org-city {
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0
}

input#withoutgrouping {
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0
}

input#withoutgroupingIncomplete {
  border-color: red;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0
}

.p-fieldset-legend>a,
.p-fieldset-legend>span {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}


button.p-button.p-component.p-datepicker-trigger.p-button-icon-only {
  background: var(--ps-blue);
  background-color: var(--ps-blue);
  transition: #4a9dff 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.slider-div>span {
  color: #112D4E;
  height: 4px !important;
  opacity: 40;
}

.year-btn-div {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
}

.year-btn-save {
  height: 20px;
  width: 100px;
  border: none;
  border-radius: 10px;

  font-weight: 500;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: relative;
  transition: all 0.35s;
  text-align: center;
  display: inline-block;
  transition-duration: 0.4s;

  margin-left: 20px;
}

.year-btn-save:hover {
  background-color: #3F72AF;
  color: white;
}

.p-button {
  color: #ffffff;

  background: var(--ps-blue);
  border: 1px solid var(--ps-blue);
  padding: 0.5rem 1rem;
  font-size: 1rem;

  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 3px;
}

.p-button:hover {
  background: var(--ps-blue-hover);

}

.companyInfoNextBtn {
  margin-top: 1em;
  margin-bottom: 1em;
}

.missingFieldsStyle {
  border-color: red;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  width: 250px;
}

.companyInformationTextBoxTitle {
  display: flex;
  padding-left: 0px;

}

.companyInformationMandatoryStart {
  color: red;
  margin-left: 3px;
  margin-top: -3px;
}


.upload-second-div {
  margin-top: 6em;
  margin-left: 31%;
  width: 51em;
}

.companyIndiImportInsideDiv {
  margin-left: 37.5em;
  width: 27%;
}


.App {

  text-align: center;
}

.horizontal-slider {
  width: 100%;
  height: 50px;
}

.thumb {
  width: 30px;
  height: 30px;
  border: 2px solid #3f72af;
  background-color: #fff;
  border-radius: 100%;
  transition: all 300ms ease-in-out;
}

.thumb.active {
  background-color: #3f72af;
  border: 2px solid #3f72af;
}

.track {
  position: relative;
  background: #ebe7e7;
  transition: all 300ms ease-in-out;
}

.track.track-1 {
  background-image: linear-gradient(to left, #3f72af, white);
  transition: all 300ms ease-in-out;
}

.track.track-2 {
  background: #ebe7e7;
}

.horizontal-slider .track {
  top: 20px;
  height: 10px;
}

.horizontal-slider .thumb {
  top: 14px;
  width: 20px;
  height: 20px;
  line-height: 48px;
}

.slider__labels {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 30px;
}

.slider__labels-label {
  text-align: center;
  width: 40px;
  font-weight: bold;
  box-sizing: border-box;
  font-size: 11px;
  border-radius: 5px;
}

.thumb.thumb-0.focus-visible {
  border: 2px solid #3f72af !important;
}

.companyInfoErrorToast>div>div {
  top: 5em;
  position: absolute;
}

.SIEFileErrorList {
  color: red;
}


@media screen and (max-width: 1536px) {
  .companyInfoErrorToast>div>div {
    top: 4em;
    position: absolute;
  }

}

@media screen and (max-width: 1200px) {
  .upload-second-div {
    margin-left: 26%;
  }

  .info-year-container {
    margin-left: 25%;
  }
}

@media screen and (max-width: 1090px) {
  .info-year-container {
    width: 650px;
    margin-left: 32%;
  }

  .upload-second-div {
    margin-left: 20%;
  }

  .year-cal-box {
    width: 600px;
  }

  .info-col {
    margin-left: 19px;
  }
}

@media screen and (max-width: 960px) {
  .best_view_dialogbox {
    margin-top: -40%;
    width: 24vw;
    margin-left: -49%;
  }

}

@media screen and (max-width: 873px) {
  .info-year-container {
    margin-left: 35%;
  }
}

.CIcontentParentDiv_hideDiv {
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .best_view_dialogbox {
    margin-top: -76%;
    margin-left: -45%;
    width: 39vw;
  }
}


@media screen and (max-width: 726px) {
  .info-year-container {
    margin-left: 37%;
  }
}


@media screen and (max-width: 600px) {
  .best_view_dialogbox {
    margin-top: -69%;
    margin-left: -40%;
    width: 40vw;
  }

}

@media screen and (max-width: 540px) {
  .info-year-container {
    box-shadow: 5px 10px 8px #888888;
    border: 1px solid white;
    margin-top: 1%;
    width: 900px;
    margin-bottom: 6%;
    margin-left: 28%;
  }

  .best_view_dialogbox {
    margin-top: -70%;
    margin-left: -39%;
    width: 43vw;
  }
}

@media screen and (max-width: 440px) {
  .best_view_dialogbox {
    margin-top: -107%;
    margin-left: -44%;
    width: 70vw;
  }
}

@media screen and (max-width: 430px) {
  .best_view_dialogbox {
    margin-top: -136%;
    margin-left: -42%;
    width: 63vw;
  }
}

@media screen and (max-width: 414px) {
  .best_view_dialogbox {
    margin-top: -110%;
    margin-left: -42%;
    width: 63vw;
  }
}


@media screen and (max-width: 400px) {
  .best_view_dialogbox {
    margin-top: -139%;
    margin-left: -47%;
    width: 80vw;
  }
}

@media screen and (max-width: 390px) {
  .best_view_dialogbox {
    margin-top: -149%;
    margin-left: -42%;
    width: 63vw;
  }
}


@media screen and (max-width: 375px) {
  .best_view_dialogbox {
    margin-top: -110%;
    margin-left: -42%;
    width: 83vw;
  }
}

@media screen and (max-width: 360px) {
  .best_view_dialogbox {
    margin-top: -110%;
    margin-left: -46%;
    width: 76vw;
  }
}


@media screen and (max-width: 350px) {
  .best_view_dialogbox {
    margin-top: -120%;
    margin-left: -42%;
    width: 76vw;
  }
}


@media screen and (max-width: 344px) {
  .best_view_dialogbox {
    margin-top: -177%;
    margin-left: -53%;
    width: 74vw;
  }
}



@media screen and (max-width: 320px) {
  .best_view_dialogbox {
    margin-top: -177%;
    margin-left: -39%;
    width: 76vw;
  }
}


@media screen and (max-width: 300px) {
  .best_view_dialogbox {
    margin-top: -177%;
    margin-left: -44%;
    width: 106vw;
  }
}

@media screen and (max-width: 280px) {
  .best_view_dialogbox {
    margin-top: -177%;
    margin-left: -47%;
    width: 108vw;
  }
}

.CIcontentParentDiv {
  margin-top: -22px;
  background: #f1f1f1;
}

.import-btn-upload {
  margin-top: 24px;

}

.year-cal-label-head {
  margin-top: -10px;
  margin-bottom: 10px;

}

.p-datepicker.p-component.p-ripple-disabled {
  z-index: 1001;
  min-width: 199px;
  transform-origin: center top;
  top: 661.156px;
  left: 476.953px;
  width: 405px !important;
}

.companyInfoSideBar {
  margin-top: 4.3em;
  position: fixed;
  z-index: 10;
}


.proceed-btn-div>button {
  width: 13%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background: var(--ps-blue);
  color: white;
  margin-left: 85%;
  margin-top: 2%;
}

.companyInfoSideBarWithWIP {
  margin-top: 6.2em;
}

.no-access-div {
  width: 500px;
  padding: 20px;

  margin-top: 300px;
  color: red;
}

.year-limit-info {
  margin: 2%;
  color: #0a84e8;
  font-weight: 600;
  margin-top: 38px;
  margin-bottom: 27px;
}

.button#dropdown-split-basic {
  background: #3F72AF;
  border: none;
}

.import-sie-dropdown {
  background: #3F72AF;
  border: none;
}

.sieUploadLoadingDiv {
  height: 300px;
}

.tipsVideoTag {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.company_fieldset1>legend,
.company_fieldset2>legend {
  background-color: #DBE2EF !important;
}

.info-row .info-col:nth-child(2) {
  margin-left: 0px;
}

/* Reset all mark text to default color */
.ant-slider-mark-text {
  color: #d9d9d9;
  transition: color 0.3s, font-weight 0.3s;
}

/* Highlight only the selected mark */
.custom-slider .ant-slider-mark-text-active {
  color: #2196f3;
  font-weight: bold;
  font-size: 18px;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-slider .ant-slider-track {
  background-color: #2196f3;
  border-radius: 2px;
  left: 0%;
  width: 66.6667%;
  height: 8px;
  margin-top: -2px;
}

.companyInfoStartEndDateLbl {
  text-align: start;
}

.companyInfoFyRow {
  padding-left: 35px;
  margin-top: 10px;
}

.companyInfoStartDateLblDiv,
.companyInfoEndDateLblDiv {
  margin-bottom: 2px;
}

.best-view-dialog {
  backdrop-filter: blur(8px);
}