.notesSideBar {
    margin-top: 63px;
}

.ISparent_Management {
    display: flex;
}

.notesHeaderLine {
    margin-left: 44px;
    font-size: 15px;
    line-height: 1.2;
    font-weight: 550;
    color: black;
    padding: 10px 0px 10px 0px;
}

.notesNonDynamicColumnsLine {
    /* margin-left: 17px; */
    margin-bottom: 15px;

    margin-left: 44px;
    font-size: 15px;
    line-height: 1.2;
    /* font-weight: 550; */
    color: black;
    /* padding: 10px 0px 10px 0px; */
}

.notesLineSumColumnsLine {
    margin-left: 44px;
    margin-bottom: 15px;
}

.notesNonDynamicColumnsLineInput>input {
    border-radius: 10px;
    height: 35px;
    width: 170px;
}

.notesDynamicColumnsLineInput>input {
    border-radius: 10px;
    height: 35px;
    width: 170px;
}

.notesTextArea {
    border-radius: 10px;
}

#notesAddNewLineIcon {
    color: #104ca5bf;
    margin-left: 13px;
    position: relative;
    cursor: pointer;
    font-size: small;
    left: -5px;
    top: -2px;
    cursor: pointer;
}

.notesSaveBtnCenter {
    margin-top: 2%;
    margin-bottom: 3.3%;
}

.p-overflow-hidden {
    overflow-x: hidden;
    overflow-y: scroll;
}

#notesDialogAddDeleteIcon {
    color: #3f72af;
    top: -21px;
    font-size: 20px;
    position: relative;
    cursor: pointer;
    margin-left: 6px;
}

.notesAddNewColumnLbl {
    /* width: 50%; */
    text-align: right;
    cursor: pointer;
    /* Align text to the right */
    color: #4b7439;
    text-decoration: underline;
    font-style: italic;
    font-size: 14px;
    position: relative;
    /* Make the label position relative for positioning the text */
    height: 30px;
    margin-bottom: -14px;
    top: 13px;
}

.notesFyInputMask {
    width: 172px;
    font-size: 14px;
    top: -7px;
    position: relative;
    line-height: 1.2;
    font-weight: 550;
    color: black;
}

.notesToDateFyInputMask {
    width: 172px;
    font-size: 14px;
    top: -7px;
    position: relative;
    line-height: 1.2;
    font-weight: 550;
    color: black;
    text-align: center;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.custom-text {
    cursor: pointer;
    color: #007bff;
    /* Custom color for the text */
    padding-left: 10px;
}

.notesAddNewNotesBtn {
    margin-left: 12px;
    margin-top: 10px;
}

.notesAddBtn {
    margin-left: 12px;
    margin-top: 10px;
    border-radius: 33px;
    height: 32px;
}

.addNewNotesHeadingDropdown {
    width: 90%;
}

.subHeaderLinesInputText {
    width: 80%;
}

#notesSubHeaderLinesDelete {
    margin-left: 7px;
    font-size: 20px;
    /* animation: glow 1.5s infinite alternate; */
    /* background: #ff040459; */
    color: red;
    cursor: pointer;
    /* top: -21px;
    position: relative; */
}

#notesDeleteHeader {
    top: -24px;
    left: 2px;
    position: relative;
    cursor: pointer;
    color: #104ca5bf;
    /* color: red; */
    /* animation: glow 1.5s infinite alternate; */
    /* background: #ff040459; */
}

.subHeaderLinesDiv {
    margin-bottom: 12px;
}

.addNewNotesDialogBoxLineSUmLbl {
    padding-left: 0px;
    margin-top: 11px;
    font-style: italic;
    text-decoration: underline;
}

.notesCheckBox {
    margin-top: -17px;
    margin-left: -29px;
}

.ISFYStyle_Management {
    position: fixed;
    width: 85.9%;
    margin-left: 16.2rem;
    z-index: 1;
    margin-top: 75px;
    background-color: #dfdfdf;
}

.ISFYStyle-sidebar-close_Management {
    position: fixed;
    width: 96.8%;
    margin-left: 49px;
    margin-top: 75px;
    z-index: 1;
    background-color: #dfdfdf;
}

.ISFYStyle-sidebar-close_Management .ISExapndCollapsToggleLbl {
    margin-left: 93%;
}

@keyframes glow {
    0% {
        box-shadow: 0 0 5px red;
    }

    50% {
        box-shadow: 0 0 5px red;
    }

    100% {
        box-shadow: 0 0 10px red;
    }
}

.lineHeader {
    margin-left: 25px;
}

.notesLineLevel4Lbl {
    /* margin-left: 50px; */
    margin-left: 25px;
}

.hrLine {
    margin-bottom: 40px;
    border-top: 2px dashed #43424242;
}

#notesTrashIcon {
    color: #af3f3f;
    cursor: pointer;
    font-size: 17px;
    margin-left: 12px;
}

#notesCustomSubHeaderLinesDelete {
    margin-left: 12px;
    font-size: 20px;
    color: red;
    cursor: pointer;
}

.notesAddNewColumnRowLbl {
    text-align: right;
    cursor: pointer;
    color: #4b7439;
    text-decoration: underline;
    font-style: italic;
    font-size: 14px;
    /* position: relative; */
    height: 30px;
    /* margin-bottom: -14px; */
    /* top: -9px; */
    display: flex;
}

.AddNewColumnCustLbl {
    display: -webkit-box;
}

.ISParentRow_Notes {
    width: 100.4%;
    margin-left: -5px;
    margin-top: 10.66%;
}

.ISSideBarOpen_Notes {
    border-radius: 10px;
    margin-left: 251px;
    margin-right: 0%;
    margin-top: -19px;
    min-height: 657px;
    margin-bottom: 3.3%;
}

.ISSideBarClose_Notes {
    border-radius: 10px;
    margin-left: 2.6%;
    margin-right: 0%;
    margin-top: -37px;
    margin-bottom: 3.3%;
}


.NotesExapndCollapsToggleLbl {
    text-align: end;
    margin-bottom: 0;
    margin-top: -40px;
    margin-left: -7%;
}

button.p-button.p-component.pdf_view_notes {
    margin-left: 87%;
    margin-top: 22px;
}

@media screen and (max-width: 1536px) {
    .ISSideBarOpen_Notes {
        border-radius: 10px;
        margin-left: 275px;
        margin-top: -0.5em;
        margin-bottom: 3.3%;
    }

    .ISSideBarClose_Notes {
        border-radius: 10px;
        margin-left: 3.5%;
        margin-right: 2%;
        margin-top: -33px;
        margin-bottom: 3.3%;
        width: 97.5%;
    }

    .ISParentRow_Notes {
        width: 101.4%;
        margin-left: -16px;
        margin-top: 12.65%;
    }
}