.main-container-sidebarOpenClass {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    position: absolute;
    float: right;
    overflow: hidden;
    /* overflow-y: auto; */
    height: 100vh;
}

.main-container-sidebarCloseClass {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    position: absolute;
    float: right;
    overflow: hidden;
    height: 100vh;
    margin-top: 0.3em;
}


.tourguide_toogle_btn {
    width: 12em;
    background: white;
    border: none;
    background-color: transparent;
}

.sidebar {
    background: rgb(248, 247, 247);
    color: white;
    margin-top: 2%;
    height: 100vh;
    overflow-y: auto;
}

@media screen and (max-width: 1536px) {
    .main-container-sidebarOpenClass {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        display: flex;
        position: absolute;
        float: right;
        overflow-y: auto;
        height: 85vh;
    }

    .main-container-sidebarCloseClass {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        display: flex;
        position: absolute;
        float: right;
        overflow: hidden;
        height: 100vh;
        margin-top: 0.3em;
    }

}

@media screen and (max-width: 960px) {
    .main-container-sidebarOpenClass {
        width: 45px;
    }
}



@media screen and (max-width: 960px) {
    .main-container-sidebarOpenClass {
        width: 45px;
    }


    .CIcontentParentDiv {
        margin-top: -20px;
        margin-left: -19%;
    }
}

@media screen and (max-width: 890px) {
    .main-container-sidebarOpenClass {
        width: 45px;
    }

    .sidebar {
        margin-top: -22%;
    }

    .CIcontentParentDiv {
        margin-top: -20px;
        margin-left: -24%;
    }
}

@media screen and (max-width: 960px) {
    .main-container-sidebarOpenClass {
        width: 45px;
    }
}

main {
    padding: 10px;
}

.title {
    font-size: 3rem;
    display: grid;
    place-items: center;
}




.sidebarExtent {
    background: rgb(248, 247, 247);
    color: white;
    height: 123vw;
    overflow-y: auto;
    min-height: 40vw;
    max-height: 130vw;
}

.top_section {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 3%;
}

.bars {
    width: 30px;
    margin-top: 11px;
}

.hide {
    display: none;
}

.routes {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.link {
    display: flex;
    color: rgb(0, 0, 0);
    gap: 10px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    text-decoration: none;
}

.link1,
.link2,
.link3,
.link4,
.link5,
.link6,
.link7,
.link8,
.link9,
.link10,
.link11 {
    display: flex;
    color: rgb(0, 0, 0);
    gap: 10px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    text-decoration: none;
}

.sidebarActive {
    display: flex;
    color: rgb(255 255 255);
    gap: 10px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    text-decoration: none;
    /* background: #3f72af; */
    background: var(--ps-blue);
}

.balanceSheetLink,
.notesLink {
    display: flex;
    color: rgb(0, 0, 0);
    gap: 10px;
    padding: 1px 10px;
    border-right: 4px solid transparent;
    transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    text-decoration: none;
    cursor: pointer;
}

.link1:hover,
.link2:hover,
.link3:hover,
.link4:hover,
.link5:hover,
.link6:hover,
.link7:hover,
.link8:hover,
.link9:hover,
.link10:hover,
.link11:hover {
    border-right: 4px solid rgb(27, 26, 26);
    background: rgb(180, 180, 184);
    transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    color: black;
}

.balanceSheetLink:hover,
.notesLink:hover {
    border-right: 4px solid rgb(27, 26, 26);
    background: rgb(180, 180, 184);
    transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    color: black;
}


.link:hover {
    border-right: 4px solid rgb(27, 26, 26);
    background: rgb(180, 180, 184);
    transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    color: black;
}

.link_text {
    white-space: nowrap;
    font-size: 15px;
    padding-top: 10px;
}

.menu_item {
    display: flex;
    gap: 10px;
}

.menu_container {
    display: flex;
    flex-direction: column;
}

.menu_container .link {
    padding-left: 20px;
    border-bottom: #fff 0.5px solid;
}

.icon {
    font-size: 25px;
    color: red;
}

.link:active {
    box-shadow: 0 0 20px rgba(0, 0, 0.5);
}

.tickIcon {
    width: 22px;
    position: absolute;
    margin-left: -10px;
}

.new-annual-report-create {
    width: 30px;
    height: 30px;
    margin-top: 12px
}

.new-annual-report-create-button {
    border: none;
    border-radius: 5px;
    /* background-color: #3F72AF; */
    background-color: var(--ps-blue);
    color: white;
    width: 170px;
    height: 40px;

}

h5.titlePagenamesidebar {
    color: black;
    height: 0%;
    padding: 0px;
    margin: opx;
    margin: 0px;
    position: relative;
    z-index: 999;
    margin-top: 17px;
    margin-right: 32px;
}

p.subtext-annual-report {
    color: black;
    margin: 0px;
    position: relative;
    margin-right: 39px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 16px;
}


#sidebar_iconTourGuide {
    margin-bottom: 3px;
}

.tourguide_toogle_btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 1px;
}


.sideBarNotAccessableToast {
    top: 83%;
    right: 20px;
}

.menu-names-link {
    padding-top: 9px;
}



@media screen and (max-width: 960px) {
    .main-container-sidebarOpenClass {
        width: 45px;
    }
}


@media screen and (max-width: 960px) {
    .main-container-sidebarOpenClass {
        width: 45px;
    }


    .CIcontentParentDiv {
        margin-top: -20px;
        margin-left: -19%;
    }
}

@media screen and (max-width: 890px) {
    .main-container-sidebarOpenClass {
        width: 45px;
    }

    .sidebar {
        margin-top: -22%;
    }

    .CIcontentParentDiv {
        margin-top: -20px;
        margin-left: -24%;
    }
}

@media screen and (max-width: 960px) {
    .main-container-sidebarOpenClass {
        width: 45px;
    }
}

@media screen and (max-width: 850px) {
    .main-container-sidebarOpenClass {
        width: 45px;
    }

    .sidebar {
        margin-top: -23%;
    }

    .CIcontentParentDiv {
        margin-top: -20px;
        margin-left: -29%;
    }
}

@media screen and (max-width: 800px) {
    .CIcontentParentDiv {
        margin-top: -20px;
        margin-left: -35%;
    }
}

@media screen and (max-width: 750px) {
    .CIcontentParentDiv {
        margin-top: -20px;
        margin-left: -39%;
    }
}

.SB-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent background */
    display: flex;
    justify-content: left;
    align-items: left;
}

.react-joyride__tooltip > div > div{
    /* border: 1px solid black; */
    padding: 8px 10px;
}