.admin-page-total-content {
  margin-top: 3.7%;
  margin-bottom: 5%;
}

.representative-sidebar-div {
  margin-top: 4.3em;
  position: fixed;
}

.Page_Title_rep {
  margin-top: 1%;
  margin-bottom: 6px;

}

.Page_Title_T1_rep_name {
  margin-left: 3px;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  color: #3f72af !important;
}

.Page_Title_Rep_Year {
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  height: 36px;
  border: solid 1px transparent;
  width: 50%;
  margin-right: 57px;

}

.AdminContent {
  margin-left: 23%;
  margin-top: 2%;
  font-size: 18px;
  font-weight: bolder;
}

.toast-top {
  margin-top: 5%;
}

.AdminSettingPage_Main {
  margin-left: 15%;
  margin-right: 1%;
}

.rep-accordion {
  width: 100%;
}

.BSFYStyleRep {
  position: fixed;
  z-index: 1;
  width: 85.8%;
  margin-left: 13.6%;
  background-color: #dfdfdf;
  margin-top: 75px;
}

.BSFYStyleRepSidebar {
  position: fixed;
  z-index: 1;
  width: 96.3%;
  margin-left: 3%;
  background-color: #dfdfdf;
  margin-top: 75px;
}

.card-admin-page {
  width: 100%;
  margin-bottom: 3%;
}

.admin-toolbar {
  padding: 0.5% 3%;

}

.Acc-Company-Info {
  width: 32%;
}

button.p-button.p-component.edit-button-admin-page.p-button-icon-only.p-button-outlined.p-button-rounded {
  margin-left: 0rem;
}

.editbtnadminsetting {
  width: 3rem;
}

.edit-btn-col {
  text-align: end;
  margin-top: -3%;
}

.react-switch-bg {
  background-color: #3f72af !important;
}

.ISActiveCollapseLbl {
  position: relative;
  font-size: 14px;
  font-weight: 600;
}

.p-column-filter-add-rule {
  display: none;
}

.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled.p-column-filter-matchmode-dropdown {
  display: none;
}

input.p-inputtext.p-component.rep-city-box {
  width: 48%;
}

label.rep-label-city {
  margin-left: -5%;
}

.rep-div-city {
  display: grid;
}

input.p-inputtext.p-component.rep-city-box {
  width: 30%;
}

label.rep-label-date {
  margin-left: 38%;
}

.rep-div-date {
  display: grid;
}

.adminPageCompanyInfoMainDiv {
  width: 50%;
  margin-left: 5%;
}

.adminPageCompanyInfoKey {
  text-align: center;
}

.adminPageCompanyInforTitle {
  width: 152%;
  font-size: 110%;
  line-height: 44px;
  margin-top: 7px;
}

.adminPageCompanyInfoTitleRow {
  line-height: 55px;
  margin-bottom: 14px;
}

.adminPageCompanyInfoEditPopUpTitles {
  margin-bottom: 0px;
  font-weight: 600;
}

.field {
  margin-bottom: -14px;
}

.adminPageEmailExistsAlready {
  color: red;
}

.adminPageUserListLabelCntr {
  background: #dbe2ef52;
  font-size: larger;
}

.adminPageUserListLabel {
  color: #3f72af;
}

.Admin-toggle-open {
  text-align: center;
  margin-left: 30%;
}

span.p-calendar.p-component.p-inputwrapper.rep-date-box.p-calendar-w-btn.p-calendar-w-btn-right {
  width: 40%;
  margin-left: 44%;
}

label.rep-label-date {
  margin-left: 38%;
}

.rep-div {
  border-radius: 10px;
  margin-left: 13%;
  margin-right: 0%;
  margin-top: 132px;
  margin-bottom: 9.3%;
  min-height: 710px;
}

.rep-div-sidebar {
  border-radius: 10px;
  margin-left: 2.4%;
  margin-right: 0%;
  margin-top: 135px;
  margin-bottom: 9.3%;
  min-height: 710px;
}

.Page_Title_Year {
  height: 36px;
  border: solid 1px transparent;
  margin-left: 13px;
  margin-right: 57px;

  margin-bottom: -22px;
  font-weight: bold;
}

button.p-button.p-component.pdf_view_rep {
  margin-top: 1%;
  text-align: end;
  margin-bottom: 1%;
  margin-right: 19px;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save {
  border: 1px solid black;
}

.p-datatable .p-datatable-tbody>tr>.representativeRowEdit .p-row-editor-save {
  visibility: hidden;
}

.signTypeEditDropdown {
  width: 100%;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  display: none;
}



@-moz-document url-prefix() {
  .rep-div {
    margin-top: 156px !important;
  }

  .rep-div-sidebar {
    margin-top: 159px !important;
  }
}


@media screen and (max-width: 1536px) {

  .BSFYStyleRep {
    border-radius: 0;
    position: fixed;
    width: 81.4% !important;
    margin-left: 17.8% !important;
    z-index: 1;
    margin-top: 4.8% !important;
    background-color: #dfdfdf;
  }

  .rep-div {
    margin-left: 17% !important;
    margin-top: 126px !important;
  }

  .BSFYStyleRepSidebar {
    position: fixed;
    width: 96% !important;
    margin-left: 50px !important;
    z-index: 1;
    background-color: #dfdfdf;
    margin-top: 4.8% !important;
  }

  .rep-div-sidebar {
    margin-top: 129px !important;
  }

  button.p-button.p-component.pdf_view_notes {
    margin-left: 85% !important;
    margin-top: 22px;
  }

  @-moz-document url-prefix() {
    .rep-div {
      margin-left: 17% !important;
      margin-top: 150px !important;
    }

    .rep-div-sidebar {
      margin-top: 152px !important;
    }
  }
}