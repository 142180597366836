.my-profile-main-div {
    margin-top: 4.5%;
    margin-bottom: 5%;
}

.Profile-toggle-open {
    text-align: center;
    margin-left: -6%;
}

.my-profile-div {
    width: 70%;
    margin-left: 15%;
}

.profile-accordion {
    width: 70%;
    margin-left: 18%;

}

th.p-sortable-disabled.p-selection-column.mypackage-checkbox {
    width: 57px;
}

.mypackage-companyName {
    cursor: pointer;
}

th.dashboard-created-on {
    width: 8%;
}

.p-toolbar {
    background: white;
    padding: 0.5rem;
    border-radius: 3px;
    gap: 0.5rem;
}

.profile_mb-4 {
    margin-bottom: 0.5rem !important;
    margin-top: -13px;
    border: none;
}

.p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    font-size: 15px;
    color: #495057;
    background: #DBE2EF;
    transition: box-shadow 0.2s;
}

button.p-button.p-component.dashboard-receipt-bill.p-button-icon-only {
    margin-left: 13px;
    text-align: center;
}


.myProfile-Name,
.myProfile-Email,
.myProfile-Contactno,
.myProfile-Role,
.mypackage-invoice .my-reports-select-button,
.dashboard-togglebtn,
.mypackage-packagename,
.mypackage-startingdate,
.mypackage-validuntil,
.mypackage-maxiusage,
.mypackage-actualusage,
.adminPageCompanyInfoEditPopUpTitles,
.mypackage-dialoge-box-22,
.mypackage-company-name,
.mypackage-organization-number,
.mypackage-createddate,
.mypackage-reportstatus {

    font-weight: 600;
}


.MyProfile-companyName {
    cursor: pointer;
}

.myProfilePaymentSuccess {
    padding-left: 18%;
    padding-right: 12%;
}

span.myprofile_invoice_nametag {
    margin-top: 9px;
}

.my-profile-sidebar {
    height: 100vh;
    /* position: fixed; */
    /* margin-top: 3.5%; */
}

.payment-green-back-drop {
    padding: 3%;
}

.payment-tick {
    font-size: 6rem;

}