.SRU-companyInfoErrorToast>div>div {
    top: 5em;
    position: absolute;
}

.SRU-companyInfoSideBarWithWIP {
    margin-top: 6.2em;
}

.SRU-companyInfoSideBar {
    margin-top: 4.3em;
    position: fixed;
    z-index: 10;
}

.SRU-ISAmountBoxDisabled {
    text-align-last: right;

}


.SRU-INK2RFieldsStyle {
    font-size: 15px;
    line-height: 1.2;

}

.SRU-INK2RFYStyle {
    position: fixed;
    width: 86.2%;
    margin-left: 15.7rem;
    z-index: 1;
    margin-top: 75px;
    background-color: #dfdfdf;
    height: 8%;
}

.SRU-INK2RFYStyle-sidebar-close {
    position: fixed;
    width: 95.5%;
    margin-left: 71px;
    margin-top: 77px;
    z-index: 1;
    background-color: #dfdfdf;
    height: 8%;
}

.SRU-INK2RFYStyle-sidebar-close .SRU-INK2RExapndCollapsToggleLbl {
    margin-left: 90%;
}

.SRU-INK2RFYStyle-sidebar-close .SRU-INK2RExapndCollapsToggleLbl {
    margin-left: 0%
}

.SRU-INK2Rparent {
    display: flex;
}

.SRU-INK2Rparent-final {
    display: flex;
    margin-bottom: -7px;
}

.SRU-INK2RParentRow {
    width: 100%;
    margin-left: 0%;
}


.SRU-Page_Title_T1_INK2R {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    color: rgb(177, 84, 34) !important;

}

.SRU-ISAmountBoxCol {
    display: flex;
}

.SRU-INK2RAmountBoxCol {
    display: flex;
}

.SRU-INK2RFYMainDiv {
    font-size: 17px;
    font-style: normal;
    font-weight: 00;
    margin-top: 10px;
    color: black;
    font-weight: 550;
}

.SRU-ISFields {
    line-height: 20px;
    width: 50rem;
}


.SRU-pdf_view_INK2R {
    margin-left: 86%;
    margin-top: 9px;
}

.SRU-toggle-open-INK2R {
    margin-top: 10px;
    border-radius: 20px;
    background-color: rgb(177, 84, 34);

}


.SRU-INK2RSideBarOpen {
    border-radius: 10px;
    margin-left: 228px;
    margin-top: 9.4em;
    min-height: 657px;
    margin-bottom: 3.3%;
}

.SRU-INK2RSideBarClose {
    border-radius: 10px;
    margin-left: 3%;
    margin-right: 0%;
    margin-top: 9.4em;
    margin-bottom: 3.3%;
}

.p-accordion-header.p-highlight.SRU-INK2-accordion-Tab>.p-accordion-header-link>span.p-accordion-header-text {
    color: black;
}

.p-accordion-header.p-highlight.SRU-INK2-accordion-Tab>.p-accordion-header-link {
    background: rgb(202, 173, 157) !important;
}

.p-accordion-header.SRU-INK2-accordion-Tab>.p-accordion-header-link {
    background: rgb(202, 173, 157) !important;
}

.value-type-hidden-button {
    display: none !important;
}

.value-type-button,
button.p-inputnumber-button.p-inputnumber-button-down.p-button.p-button-icon-only.p-component.value-type-button:hover {
    background-color: #dee2e6;
    pointer-events: none;
    color: black;
}

.pi.pi-equals::before {
    content: "=";
    font-weight: bolder;
    font-size: 25px;
}

.pi.pi-equals::before {
    content: "=";
    font-weight: bolder;
    font-size: 20px;
}

button.p-inputnumber-button.p-inputnumber-button-down.p-button.p-button-icon-only.p-component.value-type-button>span {
    font-size: 11px;
}

.SRU-ISAmountBoxDisabled>input {
    width: 12.5em;
    background-color: #c7c7c79c;
    color: black;
    font-weight: bold;
}


.INK2R_div_first {
    width: 500px !important;
}

.INK2R_div_second {
    width: 500px !important;
    margin-left: 55px;
}


@media screen and (max-width: 1536px) {
    .SRU-INK2RParentRow {
        width: 100%;
        margin-top: -18px;
    }

    .INK2R_div_second {
        margin-left: 20px;
        font-size: 13px;
        width: 377px !important;
    }

    .INK2R_div_first {
        font-size: 12px;
        width: 415px !important;
    }

    .SRU-INK2RFYStyle-sidebar-close {
        position: fixed;
        width: 95.5%;
        margin-left: 57px;
        margin-top: 77px;
        z-index: 1;
        background-color: #dfdfdf;
        height: 8%;
    }

    .SRU-INK2RFYStyle {
        position: fixed;
        width: 82.5%;
        margin-left: 15.7rem;
        z-index: 1;
        margin-top: 75px;
        background-color: #dfdfdf;
        height: 8%;
    }

    .INK2R_second_col1 {
        width: 355px;
    }

}