.SRU-companyInfoErrorToast>div>div {
    top: 5em;
    position: absolute;
}

.SRU-companyInfoSideBarWithWIP {
    margin-top: 6.2em;
}

.SRU-companyInfoSideBar {
    margin-top: 4.3em;
    position: fixed;
    z-index: 10;
}

.SRU-ISAmountBoxDisabled {
    text-align-last: right;
    height: 36px;
    column-width: 100px;
    padding-right: 20px;
    border-radius: 20px;
    margin-right: 50px;
    width: 10em;
}

.SRU-INK2SFieldsStyle {
    font-size: 15px;
    line-height: 1.2;

}

.SRU-INK2SFYStyle {
    position: fixed;
    width: 80.2%;
    margin-left: 19.2rem;
    z-index: 1;
    margin-top: 75px;
    background-color: #dfdfdf;
    height: 8.5%;
}

.SRU-INK2SFYStyle-sidebar-close {
    position: fixed;
    width: 93.8%;
    margin-left: 49px;
    margin-top: 75px;
    z-index: 1;
    background-color: #dfdfdf;
    height: 8.5%;
}

.SRU-INK2SFYStyle-sidebar-close .SRU-INK2SExapndCollapsToggleLbl {
    margin-left: 90%;
}

.SRU-INK2SFYStyle-sidebar-close .SRU-INK2SExapndCollapsToggleLbl {
    margin-left: 0%
}

.SRU-INK2Sparent {
    display: flex;
}

.SRU-INK2Sparent-final {
    display: flex;
    margin-bottom: -7px;
}

.SRU-INK2SParentRow {
    width: 100%;
    margin-left: 0%;
}


.SRU-Page_Title_T1_INK2S {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    color: rgb(177, 84, 34) !important;

}

.SRU-ISAmountBoxCol {
    display: flex;
    /* border: 1px solid red; */
    margin-left: 5%;
}

.SRU-INK2SAmountBoxCol {
    display: flex;
}

.SRU-INK2SFYMainDiv {
    font-size: 17px;
    font-style: normal;
    font-weight: 00;
    margin-top: 10px;
    color: black;
    font-weight: 550;
}

.SRU-ISFields {
    line-height: 20px;
    width: 50rem;
}


.SRU-pdf_view_INK2S {
    margin-left: 86%;
    margin-top: 9px;
}

.SRU-toggle-open-INK2S {
    margin-top: 10px;
    border-radius: 20px;
    background-color: rgb(177, 84, 34);

}


.SRU-INK2SSideBarOpen {
    border-radius: 10px;
    margin-left: 296px;
    margin-right: 3%;
    margin-top: 9.4em;
    min-height: 657px;
    margin-bottom: 3.3%;
}

.SRU-INK2SSideBarClose {
    border-radius: 10px;
    margin-left: 2%;
    margin-right: 3%;
    margin-top: 9.4em;
    margin-bottom: 3.3%;
}

.p-accordion-header.p-highlight.SRU-INK2-accordion-Tab>.p-accordion-header-link>span.p-accordion-header-text {
    color: black;
}

.p-accordion-header.p-highlight.SRU-INK2-accordion-Tab>.p-accordion-header-link {
    background: rgb(202, 173, 157) !important;
}

.p-accordion-header.SRU-INK2-accordion-Tab>.p-accordion-header-link {
    background: rgb(202, 173, 157) !important;
}

.ink2s-options-label {
    padding-left: 5px;
    padding-right: 5px;
}



.INK2S_div_first {
    width: 500px !important;
}

.INK2S_div_second {
    width: 500px !important;
    margin-left: 20px;
}

/* .INK2S_inputbox_div {
    border: 1px solid red !important;
} */

@media screen and (max-width: 1536px) {

    .INK2S_div_first {
        width: 420px !important;
        font-size: 14px;
    }

    .INK2S_div_second {
        width: 400px !important;
        font-size: 14px;
        /* margin-left: 20px; */
    }
}