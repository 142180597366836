.demo-work-carousel {
    border: 12px solid #3F72AF;

    margin-top: 1%;
    border-radius: 4%;

}


.demo-slide-image {
    width: 100%;

}

h4.demo-title-heading {
    margin-top: 0%;
    font-weight: 500;
}

.caro-demo .p-carousel-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 59vh;
    margin-top: 2%;
}


@media screen and (max-width: 1920px) {
    .demo-work-carousel {
        width: 54%;
        height: 70vh;
    }
}

@media screen and (max-width: 1111px) {
    .demo-work-carousel {
        width: 86%;
        height: 71vh;
    }

    .caro-demo .p-carousel-container {
        height: 52vh;
    }
}


@media screen and (max-width: 1536px) {
    .caro-demo .p-carousel-container {
        height: 58vh;
    }
}


@media screen and (max-width: 1024px) {
    .Demo_slide_video {
        width: 893px !important;
        height: 590px !important;
    }
}

@media screen and (max-width: 960px) {
    .demo-work-carousel {
        width: 100%;
        height: 65vh;
    }

    .caro-demo .p-carousel-container {
        height: 52vh;
    }
}

@media screen and (max-width: 912px) {
    .Demo_slide_video {
        width: 739px !important;
        height: 465px !important;
    }
}


@media screen and (max-width: 768px) {
    .demo-work-carousel {
        width: 100%;
        height: 67vh;
    }

    .caro-demo .p-carousel-container {
        height: 50vh;
    }

    .Demo_slide_video {
        width: 699px !important;
        height: 464px !important;
    }
}

@media screen and (max-width: 700px) {
    .caro-demo {
        margin-top: 10%;
    }

    .Demo_slide_video {
        width: 550px !important;
        height: 345px !important;
    }

}

@media screen and (max-width: 550px) {
    .caro-demo {
        margin-top: 10%;
    }

    .Demo_slide_video {
        width: 417px !important;
        height: 277px !important;
        margin-top: 9%;
    }
}



@media screen and (max-width: 500px) {
    .demo-work-carousel {
        width: 100%;
        height: 43vh;
    }

    .caro-demo .p-carousel-container {
        height: 50vh;
    }

    .about-div-home {
        height: 65vh;
    }

    .demo-slide-image {
        width: 100%;
        height: 34vh;
    }
}

@media screen and (max-width: 444px) {
    .demo-work-carousel {
        width: 100%;
        height: 43vh;
    }

    .caro-demo .p-carousel-container {
        height: 50vh;
    }

    .about-div-home {
        height: 69vh;
    }

    .demo-slide-image {
        width: 100%;
        height: 34vh;
    }
}


@media screen and (max-width: 414px) {
    .Demo_slide_video {
        width: 325px !important;
        height: 199px !important;
        margin-top: 9%;
    }
}

@media screen and (max-width: 360px) {
    .caro-demo {
        margin-top: 10%;
    }
}

@media screen and (max-width: 315px) {
    .Demo_slide_video {
        width: 235px !important;
        height: 163px !important;
        margin-top: 20%;
    }
}