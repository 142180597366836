.template-file-upload {
    /* border: 1px solid black; */
    text-align: center;
    padding: 10px 0px;
}

.template-drag-and-drop {
    /* border: 1px solid blue; */
    width: 40%;
}

.template-data-table-div {
    padding: 20px 0px;
}

.template-data-table {
    width: 98%;
}

.templateValidFromTitle {
    width: 8%;
}

.templateValidToTitle {
    width: 8%;
}

.templateAccDescTitle {
    width: 22%;
}

.templateSkMapTitle {
    width: 8%;
}

#tempkatePageDeleteIcon {
    margin-top: -5px;
}

.templatePageDeleteBtn {
    margin-left: 2%;
}